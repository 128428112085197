import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from "react-bootstrap/Button";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import MaterialTable from 'material-table';
import Jumbotron from 'react-bootstrap/Jumbotron';
//import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import {
    
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
  import DateFnsUtils from "@date-io/date-fns";
  import moment from "moment";


  import ViewAllConsumerAccount from '../methods/ViewAllConsumerAccount';
  import ViewParticularAccount from '../methods/ViewParticularAccount';
  import GenerateBillNo from '../methods/GenerateBillNo';
  import ViewChargesMaster from '../methods/ViewChargesMaster';
  import ViewSopValue from '../methods/ViewSopValue';
  import ViewMeterStatus from '../methods/ViewMeterStatus';
  import AddNewBill from '../methods/AddNewBill';
  import ViewAllBillRecord from '../methods/ViewAllBillRecord';
  import JEBillPaymentDialog from './JEBillPaymentDialog';
  import JEBillPaymentPartDialog from './JEBillPaymentPartDialog';
  import { min } from "date-fns";
import ViewAllBillRecordPartPayment from '../methods/ViewAllBillRecordPartPayment';

export default class JEBillPayment extends React.Component {
     constructor(props){
         super(props);  
         const minDate = new Date();
         const maxDate = new Date();
         minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
         minDate.setHours(0, 0, 0, 0);
         maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
         maxDate.setHours(0, 0, 0, 0);  
        this.state={        
        message:'',       
        vColor:'',
        show:false,
   //     setShow:false,
        allBillRecord:[],
        allBillRecordPartPayment:[],
        billMonth:minDate,
        viewDataMonthYearPartPayment:minDate,
        showAlert:false
        
    }
}
    componentDidMount=()=>{
        const{billMonth}=this.state 
           
        this.handleViewAllBillRecord(billMonth);
    }

    setViewDataMonthYear=(e)=>{
        this.setState({
            viewDataMonthYear:e
        })
        this.handleViewAllBillRecord(e)

    }
    setViewDataMonthYearPartPayment=(e)=>{
        this.setState({
            viewDataMonthYearPartPayment:e
        })
        this.handleViewAllBillRecordPartPayment(e)
    }

    setShow=()=>{
        this.setState({
            showAlert:false
        })
    }

    handleViewAllBillRecord=(billMonth)=>{
        ViewAllBillRecord(billMonth).then(resp=>{
            if(resp){
                if(resp.data==='No'){
                    this.setState({
                        message:'No Record Found',
                        showAlert:true,
                        vColor:'danger',
                        allBillRecord:[],
                    })
                }
                else{
                    this.setState({
                        message:'',
                        vColor:'',
                        allBillRecord:resp.data
                    })
                }
            }
            else{

            }
        })
    }

    handleViewAllBillRecordPartPayment=(billMonth)=>{
        ViewAllBillRecordPartPayment(billMonth).then(resp=>{
            if(resp){
                if(resp.data==='No'){
                    this.setState({
                        message:'No Record Found',
                        showAlert:true,
                        vColor:'danger'
                    })
                }
                else{
                    this.setState({
                        message:'',
                        vColor:'',
                        allBillRecordPartPayment:resp.data
                    })
                }
            }
            else{

            }
        })
    }


    




    render(){        
        console.log(this.state.oldReading)
        return(
            <div>
                <Container fluid>
                {/* <Row>
                    <Col style={{textAlign:'center'}}><h3><b><em><u> BILL PAYMENT</u></em></b></h3></Col>
                    
                </Row> */}
                
                <Tab.Container defaultActiveKey="view" transition={false} id="controlled-tab-example">
                    <Row>
                        <Col>
                                <Nav variant="tabs" defaultActiveKey="view">
                                    <Nav.Item>
                                        <Nav.Link eventKey="view">Bill Payment
                                        <b style={{color:'red',paddingLeft:'5px'}}>
                                                    ({this.state.allBillRecord.length})
                                        </b>
                                        </Nav.Link>                                        
                                    </Nav.Item>
                                    {/* <Nav.Item>                                        
                                        <Nav.Link eventKey="partPayment">Part Payment</Nav.Link>
                                    </Nav.Item>                                     */}
                                </Nav>
                        </Col>
                    </Row>
                    
                    {this.state.message? 
                    <Row>
                        <Col style={{paddingTop:'5px'}}>
                                 <Alert  show={this.state.showAlert} variant={this.state.vColor} onClose={this.setShow} dismissible>
                                            {this.state.message}
                                        </Alert>
                        </Col>
                    </Row>
                         :''}
                    <Row>
                        <Col style={{paddingTop:'30px'}}>
                            <Tab.Content sm={12}>
                                <Tab.Pane eventKey="view">
                                    <Form>
                                    <Jumbotron style={{height:'10px'}}>                                                                                                           
                                    <Row>                                       
                                        <Col>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Col sm="6">                                                  
                                                    <Form.Label>
                                                        <b>Select Month to Display Record</b>
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="6">
                                                            <MuiPickersUtilsProvider  
                                                            utils={DateFnsUtils}>      
                                                                <DatePicker    
                                                                    views={["month","year"]}                                                                    
                                                                    value={this.state.viewDataMonthYear} 
                                                                    onChange={this.setViewDataMonthYear}                                                                     
                                                                />                    
                                                            </MuiPickersUtilsProvider>                                                        
                                                    </Col>
                                                </Form.Group>
                                        </Col>                                  
                                    </Row>
                                    <Row>
                                    <Col>
                                    <hr></hr>
                                    </Col>
                                </Row> 
                                    </Jumbotron>
                                        <Row>
                                            <Col>
                                             <MaterialTable
                                                            name="Bill  Payment"
                                                            title="Bill  Payment"   
                                    
                                                            columns  ={[
                                                            {title:'S.No',field:'sno'}, 
                                                            {title:'BillMonth ', field:'billMonth'},
                                                            {title:'Bill No ', field:'billNo'},
                                                            {title:'Acccount No.',field:'accNo'},
                                                            {title:'CustomerName',field:'name'},                                                          
                                                            {title:'IssueDate',field:'issueDate'},
                                                            {title:'DueDate',field:'dueDate'},
                                                            {title:'BillType',field:'billType'},
                                                            {title:'NewReading',field:'newReading'},
                                                            {title:'OldReading',field:'oldReading'},
                                                            {title:'AmountPayable',field:'amountPayable'},
                                                            {title:'AmountPayable After Date',field:'amountPayableAfterDate'},                                                            
                                                            {title:'+More',field:'view'},
                                                                                                            
                                                        ]}
                                            data={
                                                this.state.allBillRecord.map((allBill,id)=>(
                                                    {
                                                        'sno':id+1,
                                                        'billMonth':allBill.billMonth,
                                                        'billNo':allBill.billNo,
                                                        'accNo':allBill.accountNo,
                                                        'name':allBill.name,
                                                        'issueDate':allBill.issueDate,
                                                        'dueDate':allBill.dueDate,
                                                        'billType':allBill.billType,
                                                        'newReading':allBill.NewMeterReading,
                                                        'oldReading':allBill.OldMeterReading,
                                                        'amountPayable':allBill.amountPayable,
                                                        'amountPayableAfterDate':allBill.amountPayableAfter,
                                                        'view':<JEBillPaymentDialog billRecordId={allBill.billrecord_id}/>                                 

                                                    }
                                                ))
                                            }
                                            options={{
                                                exportButton: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 20, 50, 100,this.state.allBillRecord.length],
                                                toolbar: true,
                                                paging: true
                                                
                                                }} 
                                                   />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col></Col>
                                            
                                        </Row>
                                    </Form>
                                </Tab.Pane>
                                <Tab.Pane eventKey="partPayment">
                                    <Form>
                                    <Jumbotron style={{height:'10px'}}>                                                                                                           
                                    <Row>                                       
                                        <Col>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Col sm="6">                                                  
                                                    <Form.Label>
                                                        <b>Select Month to Display Part Payment Record</b>
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="6">
                                                            <MuiPickersUtilsProvider  
                                                            utils={DateFnsUtils}>      
                                                                <DatePicker    
                                                                    views={["month","year"]}                                                                    
                                                                    value={this.state.viewDataMonthYearPartPayment} 
                                                                    onChange={this.setViewDataMonthYearPartPayment}                                                                     
                                                                />                    
                                                            </MuiPickersUtilsProvider>                                                        
                                                    </Col>
                                                </Form.Group>
                                        </Col>                                  
                                    </Row>
                                    <Row>
                                    <Col>
                                    <hr></hr>
                                    </Col>
                                </Row> 
                                    </Jumbotron>
                                        <Row>
                                            <Col>
                                             <MaterialTable
                                                            name="Part Payment"
                                                            title="Part Payment"   
                                    
                                                            columns  ={[
                                                            {title:'S.No',field:'sno'}, 
                                                            {title:'BillMonth ', field:'billMonth'},
                                                            {title:'Bill No ', field:'billNo'},
                                                            {title:'Acccount No.',field:'accNo'},
                                                            {title:'CustomerName',field:'name'},                                                          
                                                            {title:'IssueDate',field:'issueDate'},
                                                            {title:'DueDate',field:'dueDate'},                                                          
                                                            {title:'AmountPayable',field:'amountPayable'},
                                                            {title:'AmountPayable After Date',field:'amountPayableAfterDate'},                                                            
                                                            {title:'+More',field:'view'},
                                                                                                            
                                                        ]}
                                            data={
                                                this.state.allBillRecordPartPayment.map((allBill,id)=>(
                                                    {
                                                        'sno':id+1,
                                                        'billMonth':allBill.billMonth,
                                                        'billNo':allBill.billNo,
                                                        'accNo':allBill.accountNo,
                                                        'name':allBill.name,
                                                        'issueDate':allBill.issueDate,
                                                        'dueDate':allBill.dueDate,                                                        
                                                        'amountPayable':allBill.amountPayable,
                                                        'amountPayableAfterDate':allBill.amountPayableAfter,
                                                        'view':<JEBillPaymentPartDialog billRecordId={allBill.billrecord_id}/>                                 

                                                    }
                                                ))
                                            }
                                            options={{
                                                exportButton: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 20, 50, 100,this.state.allBillRecord.length],
                                                toolbar: true,
                                                paging: true
                                                
                                                }} 
                                                   />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col></Col>
                                            
                                        </Row>
                                    </Form>
                                </Tab.Pane>
                            </Tab.Content>

                          
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                </Tab.Container>
                </Container>
            </div>
        )
    }
 }