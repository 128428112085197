import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from "react-bootstrap/Button";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import MaterialTable from 'material-table';
import Jumbotron from 'react-bootstrap/Jumbotron';
import {
    
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
  import DateFnsUtils from "@date-io/date-fns";
  import ViewAllConsumerAccount from '../methods/ViewAllConsumerAccount';
  import ViewParticularAccount from '../methods/ViewParticularAccount';
  import GenerateBillNo from '../methods/GenerateBillNo';
  import ViewChargesMaster from '../methods/ViewChargesMaster';
  import ViewSopValue from '../methods/ViewSopValue';
  import ViewMeterStatus from '../methods/ViewMeterStatus';
  import AddNewBill from '../methods/AddNewBill';
  import ViewAllBillRecord from '../methods/ViewAllBillRecord';
  import ViewPreviousPendingAmount from '../methods/ViewPreviousPendingAmount';
  import ViewPrevoiusOldReadingValue from '../methods/ViewPrevoiusOldReadingValue';
  import ViewTariffFixedCharges from '../methods/ViewTariffFixedCharges';
  import { min } from "date-fns";
 
  const WAIT_INTERVAL = 1000

export default class JENewConsumer extends React.Component {
     constructor(props){
         super(props);

        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
        const newDate = new Date(Date.now() + 15 * 24*60*60*1000);

    
        this.state={
        timePeriod:'',
        accountNumber:'',
        name:'',
        type:'',
        address:'',        
        billNumber:'',
        address:'',
        meterRent:'',        
        //dueDate:'',
        billType:'Meter-Basis',
        consumerAccountDetail:[],    
        consumerWholeData:[],
        minDate : minDate,
        issueDate:minDate,
        billMonth:minDate,
        deptBillNo:'',
        //dueDate:moment(minDate).add(15,"days"), 
        dueDate:newDate,
        masterCharges:[],  
        meterStatusDetail:[], 
        meterStatus:0,
        masterPerDays:1,
        masterHoursPerDay:1,
        perDay:'',
        masterDFactor:0,
        masterMuncipal:0,
        masterED:0,
        masterInfrCess:0,
        masterSurcharges:0,
        masterLoadPerMonthRatePre:0,
        masterLoadPerMonthRate:0,
        billFrom:minDate,
        billTo:minDate,
        countMonths:'',
        newReading:'',
        newReadingDate:minDate,
        oldReading:'',
        oldReadingDate:minDate,
        ctCharges:1,
        billPeriod:'',
        loadChargesMaster:'',
        loadCharges:'',
        loadChargesPercentage:0.8,                         /**80 % of the Load  as per PSPCL rule*/
        unitsConsumed:0,
        miscCharges:'',
        miscRemarks:'',
        totalConsumption:0,
        totalMuncipalTax:0,
        totalEd:0,
        totalInfrCess:0,
        serviceCharges:0,
        totalServiceCharges:0,
        totalMeterRent:0,
        totalSurcharges:0, 
        totalAmountByDate:0,   
        totalAmountAfterDate:0, 
        totalFixedCharges:0, 
        totalRentalCharges:0,  
        SOP:0,
        sopType:0,
        sopTypeConsumerValue:0,
        sopTypeCharges:0,
        previousAdjustmentAmount:0,
        previousAdjustmentBillArrears:'',
        message:'',
        messageMaster:'',
        messageDate:'',
        vColor:'',
        show:false,
        setShow:false,
        allBillRecord:[],
        viewDataMonthYear:minDate,
        payableAfterDueAmount:'',
        finalPaymentAfter:0,
        billFlag:0,
        tenRoundOffFinalBill:0,
        totalPayable:0,
        oldValueFlag:0,
        advancePaymentReadFlag:0 ,  
        readOnlyAdjustmentAmountFlag:0,  
        readOnlyUnpaidArrearsFlag:0,
        preAdjustmentBillArrearsMonth:0,
    }
}
    componentDidMount=()=>{
             const{billMonth}=this.state              
             this.handleViewAllConsumerAccount();
             this.handleViewChargesMaster();
             this.handleViewMeterStatus();
             this.handleViewAllBillRecord(billMonth);
             
    }

    setShow=()=>{
        this.setState({
            showAlert:false
        })
    }

   

    handleViewAllConsumerAccount=(event)=>{       
        ViewAllConsumerAccount().then(resp=>{
            if(resp){
                if(resp.data==='No'){

                }
                else{
                    this.setState({
                        consumerAccountDetail:resp.data
                    })                    
                }
            }
        })
    }

    handleViewChargesMaster=()=>{
        ViewChargesMaster().then(resp=>{
            if(resp){
                if(resp.data==="No"){
                    console.log(resp.data)
                    this.setState({
                        messageMaster:'Enter Master Charges of current year.......',
                        vColor:'danger',                       
                        showAlert:true,
                    })
                }
                else{
                     this.setState({
                        messageMaster:'',
                        showAlert:false,
                        masterCharges:resp.data,
                        masterPerDays:resp.data.dueDays,
                        masterHoursPerDay:resp.data.hoursPerDay,
                        masterDFactor:resp.data.demandFactor/100,
                        masterMuncipal:resp.data.muncipal/100,
                        masterED:resp.data.ed/100,
                        masterInfrCess:resp.data.infrCess/100,
                        masterSurcharges:resp.data.surcharges/100,
                        // masterLoadPerMonthRate:resp.data.loadRate
                     })
                }
            }
        })
    }

    handleViewMeterStatus=()=>{
        ViewMeterStatus().then(resp=>{
            if(resp){
                if(resp.data){
                this.setState({
                    meterStatusDetail:resp.data
                })
                }
                else{

                }

            }
            else{

            }
        })
    }

    handleViewAllBillRecord=(billMonth)=>{        
        ViewAllBillRecord(billMonth).then(resp=>{
            if(resp){
                if(resp.data==='No'){
                    this.setState({
                        message:'No Record Found',
                        vColor:'danger',
                        allBillRecord:[],
                        showAlert:true,
                    })
                }
                else{
                    this.setState({
                        message:'',
                        vColor:'',
                        allBillRecord:resp.data,
                        showAlert:false,
                    })
                }
            }
            else{

            }
        })
    }
    handlePrevoiusOldReadingValue=(accountId)=>{        
         ViewPrevoiusOldReadingValue(accountId).then(resp=>{
             if(resp){
                 console.log(resp.data.NewMeterReading)
                 if(resp.data==='No'){
                     this.setState({
                        oldReading:'',
                        oldReadingDate:this.state.minDate, 
                        oldValueFlag:0
                     })
                 }
                 else{
                    this.setState({
                        oldReading:Number(resp.data.NewMeterReading),
                        oldReadingDate:resp.data.NewMeterReadingDate, 
                        oldValueFlag:1,
                    })

                 }
             }
         })
    }

    // handleViewAdvancePaymentAmount=(accountId)=>{
    //     ViewAdvancePaymentAmount().then(resp=>{
    //         if(resp){
    //             if(resp.data==='NO'){
    //                 this.setState({
    //                     advancePaymentReadFlag:0,
    //                 })
    //             }
    //             else{
    //                 this.setState({
    //                     advancePaymentReadFlag:1   
    //                 })

    //             }
    //         }
    //     })
    // }
    
    handleViewPreviousPendingAmount=(accountId)=>{
        const{billMonth,minDate}=this.state
        ViewPreviousPendingAmount(billMonth,accountId).then(resp=>{
            if(resp){
                if(resp.data==='No'){
                    this.setState({
                        previousAdjustmentAmount:'',                        
                        previousAdjustmentBillArrears:'',
                        oldReading:'',
                        readOnlyAdjustmentAmountFlag:0,
                        readOnlyUnpaidArrearsFlag:0,
                        preAdjustmentBillArrearsMonth:0
                        //oldReadingDate:minDate
                    })
                    
                }
                else{
                    this.setState({
                        previousAdjustmentAmount:resp.data.advanceAmount,                        
                        previousAdjustmentBillArrears:resp.data.unpaidAmount,
                        readOnlyAdjustmentAmountFlag:1,
                        readOnlyUnpaidArrearsFlag:1,
                        preAdjustmentBillArrearsMonth:resp.data.billMonth,
                       // oldReading:resp.data.NewMeterReading,
                       // oldReadingDate:resp.data.NewMeterReadingDate,
                        // minDate:resp.data.NewMeterReadingDate,
                        // maxDate:resp.data.NewMeterReadingDate,
                    })
                }
            }
        })
    }

    handleViewTariffFixedCharges=(e)=>{
            const consumerLoadKw=e
        ViewTariffFixedCharges(consumerLoadKw).then(resp=>{
            if(resp){
                if(resp.data==='NO'){
                    this.setState({
                        masterLoadPerMonthRate:0
                    })
                }
                else{
                    this.setState({
                        masterLoadPerMonthRate:resp.data.rate

                    })                    
                }
            }
        })
    }

    setViewDataMonthYear=(e)=>{
        this.setState({
            viewDataMonthYear:e
        })
        this.handleViewAllBillRecord(e)

    }

    setAccountNumber=({target:{value}})=>{
       this.setState({
           accountNumber:value
       })
       ViewParticularAccount(value).then(resp=>{
              if(resp){
                  if(resp.data==='No'){

                  }
                  else{
                      this.setState({
                        consumerWholeData:resp.data,
                        name:resp.data.name,
                        type:resp.data.type,
                        address:resp.data.address,
                        ctCharges:resp.data.ctCharges,
                        sopType:resp.data.type,
                        serviceCharges:resp.data.serviceCharges,
                        meterRent:resp.data.meterRent,
                        loadChargesMaster:resp.data.loadKw,
                        loadCharges:Number(resp.data.loadKw*this.state.loadChargesPercentage).toFixed(2)
                      })
                      this.handleViewTariffFixedCharges(resp.data.loadKw)
                  }
              }
       })

       GenerateBillNo(value,this.state.billMonth).then(resp=>{
                   if(resp){
                       if(resp.data==='No'){

                       }
                       else{
                             this.setState({
                                billNumber:resp.data
                             })
                       }

                   }                   
       })

       

    //    this.handlePrevoiusOldReadingValue(value);
       
      this.handleViewPreviousPendingAmount(value);

      
    }
        setBillNumber=(e)=>{
        this.setState({
            billNumber:e.target.value
        })
    }
    setMeterStatus=({target:{value}})=>{
      const {accountNumber}=this.state
        if(value!=3){    /**If meter is new meter(id:3) then ignore previous reading */
            this.handlePrevoiusOldReadingValue(accountNumber);                     
        }
        else{
            this.setState({
                oldReading:'',
                oldReadingDate:this.state.minDate, 
                oldValueFlag:0
            })          

        }
        this.setState({
            meterStatus:value,
            billType:value==2?'Load-Basis':'Meter-Basis',
            billFlag:value==2?1:0
        })
    }

    setIssueDate=(e)=>{
        this.setState({
            issueDate:e
        })
    }

    setDueDate=(e)=>{
        this.setState({
            dueDate:e
        })
    }
    
    setBillType=(e)=>{
        const{minDate}=this.state        
        this.setState({
            billType:e.target.value,            
            billPeriod:'',
            unitsConsumed:0,
             })
    }

    setBillMonth=(e)=>{
        this.setState({
            billMonth:e
        })
    }
    /** start Load Basis */
    setPerDay=(e)=>{
        this.setState({
            perDay:e.target.value
        })
    }

    setBillFrom=(e)=>{
        this.setState({
            billFrom:e
        })
    }

    setBillTo=(e)=>{
        this.setState({
            billTo:e
        })
        this.calCulateDateDifference(e);
    }
       /* Calculate Date's  Difference */
       calCulateDateDifference=(e)=>{          
          const{billFrom}=this.state       
          const diffDays= billFrom.getMonth() - e.getMonth() + 
                            (12*(billFrom.getFullYear() - e.getFullYear())) 
          const positiveDiffDays=Math.abs(diffDays)        
          
          if(billFrom.getTime()<=e.getTime()){
            this.setState({
                countMonths: positiveDiffDays,
                messageDate:''
             })
              
          }
          else{
              const{minDate}=this.state
              this.setState({
                  billFrom:minDate,
                  billTo:minDate,
                  messageDate:'Enter Valid Date',
                  showAlert:true,
                  vColor:'danger'
              })
          }           
       }
    /** end Load Basis */

    /**Start Meter Reading */
     setNewReading=(e)=>{
        const{oldReading}=this.state         
        //  if(oldReading<e.target.value)
        //  {
            this.setState({
                newReading:e.target.value            
             })
            
         // this.calculateTotalConsumption(e.target.value); //comment on 23-11-2021

        //  }
        //  else{
        //      this.setState({
        //          newReading:'',
        //       //   oldReading:'',
        //          messageDate:'New value Should be greater than old',
        //          vColor:'danger'

        //      })
        //  }
     }    

     setNewReadingDate=(e)=>{
        const {billType}=this.state
        this.setState({
            newReadingDate:e
         })
        this.calculateNoOfDays(e);        
       
     }

     setOldReading=(e)=>{
          this.setState({
            oldReading:e.target.value
         })
         
     }

     setOldReadingDate=(e)=>{        
        this.setState({
           oldReadingDate:e
        })      
    }

    calculateNoOfDays=(e)=>{
        const{oldReadingDate,minDate,billType,newReading}=this.state      
        var differenceTime=e.getTime()-new Date(oldReadingDate).getTime();
        var diffrenceDays=differenceTime/(1000*3600*24)

        var noOfMonths=e.getMonth()-new Date(oldReadingDate).getMonth()+
                               (12*(e.getFullYear()-new Date(oldReadingDate).getFullYear()))
        
            // console.log(noOfMonths)
         
        //  console.log(totalMonthlyServiceCharges)
        if(new Date(oldReadingDate).getTime()<e.getTime()){
            this.setState({
                billPeriod:diffrenceDays,
                timePeriod:noOfMonths,                
                messageDate:''
            })

            if(billType==='Load-Basis'){
                //console.log(diffrenceDays)
                this.calculateTotalConsumption(diffrenceDays)
                //this.calculateFixedCharges(diffrenceDays);
            }
            else if(billType==='Meter-Basis')
            {
                this.calculateTotalConsumption(newReading); //23-11-2021
            }
            

            this.calculateFixedCharges(diffrenceDays);
        }
        else{
            this.setState({
                messageDate:'Current Reading Date must be greater than the Last Reading Date.',
                showAlert:true,
                vColor:'danger',
                billPeriod:'',
                newReadingDate:minDate

            })
        }        
    }


    

/*-----Fixed Charges-----*/
    calculateFixedCharges=(billPeriod)=>{
        const{loadCharges,masterLoadPerMonthRate}=this.state
        const totalFixedChargesVar=loadCharges*masterLoadPerMonthRate*billPeriod*12/365;
        this.setState({
            totalFixedCharges:Number(totalFixedChargesVar).toFixed()
        })
    }

    calculateTotalConsumption=(newReadingVarNoOfDays)=>{ 
        const{billType}=this.state        
        if(billType==='Meter-Basis')
        {
            const {oldReading,ctCharges}=this.state
            const readingDiffernce=newReadingVarNoOfDays-oldReading  
            const totalConsumptionVar=readingDiffernce*ctCharges
                this.setState({
                    totalConsumption:Number(totalConsumptionVar).toFixed(2),
                    unitsConsumed:Number(readingDiffernce).toFixed()
                })
        }
        else if(billType==='Load-Basis'){            
            const dFactorVar=this.state.masterDFactor           
            const {loadCharges,masterHoursPerDay}=this.state           
            const totalConsumptionLoadBasisVar=loadCharges*newReadingVarNoOfDays*masterHoursPerDay*dFactorVar                     
            this.setState({
                totalConsumption:Number(totalConsumptionLoadBasisVar).toFixed()
            })
        }   
        else{
        }
          this.SopTypeValue();
    }

    SopTypeValue=()=>{
        const{sopType}=this.state
      ViewSopValue(sopType).then(resp=>{
          if(resp){
            if(resp.data==='No'){

            }
            else{
                const sopData=resp.data
                const {totalConsumption}=this.state
                const sopResult=totalConsumption*sopData
                this.setState({
                    sopTypeCharges:resp.data,
                    sopTypeConsumerValue:Number(sopResult).toFixed()
                })

                this.calculateMunicipalTax(sopResult);
            }           
          }
          else{

          }              
      })
    }

    calculateMunicipalTax=(sopResult)=>{
            const{masterMuncipal}=this.state
            //console.log(masterMuncipal)
            const totalMuncipalVar=sopResult*masterMuncipal
            //console.log(sopResult)

            this.setState({
                totalMuncipalTax:Number(totalMuncipalVar).toFixed()
            })
            this.calculateEd()

    }

    calculateEd=()=>{
        const {sopTypeConsumerValue,masterED}=this.state
        const totalEdVar=sopTypeConsumerValue*masterED
        //const totalEdVar=(totalEdVar1).toFixed(2); /**upto 2 decimal places */
        this.setState({
            totalEd:Number(totalEdVar).toFixed()
        })
        this.calculateServiceCharges()
    }

    calculateServiceCharges=()=>{
        const{serviceCharges,timePeriod,billPeriod}=this.state    
       // console.log(timePeriod)    
        var totalServiceChargesVar=Number(serviceCharges/30)*billPeriod
        this.setState({
            //totalServiceCharges:Number(serviceCharges).toFixed()*Number(timePeriod)
            totalServiceCharges:Number(totalServiceChargesVar).toFixed()
        })
        this.calculateInfrCess();
    }

    calculateInfrCess=()=>{
        const {sopTypeConsumerValue,masterInfrCess,totalMuncipalTax,totalEd,totalServiceCharges}=this.state
        const totalInfrCessVar=sopTypeConsumerValue*masterInfrCess
        const totalTaxChargesVar=Number(totalMuncipalTax)+Number(totalEd)+Number(totalInfrCessVar)
        // console.log(sopTypeConsumerValue)
        // console.log(masterInfrCess)
        this.setState({

            totalInfrCess:Number(totalInfrCessVar).toFixed(),
            totalTaxCharges:Number(totalTaxChargesVar).toFixed()
        })
        this.calculateMeterRent()
    }

    calculateMeterRent=()=>{
        const {meterRent,timePeriod,billPeriod}=this.state
       
        var totalMeterRentVar=(meterRent/30)*billPeriod
        this.setState({
          //totalMeterRent:Number(meterRent)*Number(timePeriod)
          totalMeterRent:Number(totalMeterRentVar).toFixed()
        })
        this.calculateSurcharge();
    }

    calculateSurcharge=()=>{
        const {masterSurcharges}=this.state
         this.setState({
             totalSurcharges:Number(masterSurcharges)
         })
        
    }

    calculateAmountPayableByDate=(previousAdjustmentBillArrearsData)=>{       
    const{sopTypeConsumerValue,totalMuncipalTax,totalEd,totalServiceCharges,totalInfrCess,
        totalMeterRent,totalFixedCharges,miscCharges,previousAdjustmentAmount}=this.state
        
        var previousAdjustmentBillArrearsVar=previousAdjustmentBillArrearsData
        
        const totalAmountByDateChargesVar=Number(sopTypeConsumerValue)+Number(totalMuncipalTax)
                                   +Number(totalServiceCharges)+Number(totalInfrCess)
                                   +Number(totalMeterRent)+Number(totalEd)
       

        var totalPayableAmountVar= Number(totalAmountByDateChargesVar)+Number(totalFixedCharges)+
                                   Number(miscCharges)+Number(previousAdjustmentBillArrearsVar)
                                  -Number(previousAdjustmentAmount)

     
        
           
        this.setState({
            totalAmountByDate:Number(totalPayableAmountVar).toFixed(),
           // totalAmountByDateTenRoundOff:(Math.round(totalAmountByDateVar / 10) * 10),
          
        })  
       this.calculateAmountPayableAfterDate(totalPayableAmountVar)      
    }

    calculateAmountPayableAfterDate=(totalAmountByDate)=>{
        const {totalSurcharges}=this.state   
        var totalAmountByDateVar=totalAmountByDate     
        var totalAmountAfterDateVarFinal=Number(totalAmountByDateVar)*Number(totalSurcharges)
        var totalFinalPayableAmount=Number(totalAmountAfterDateVarFinal)+Number(totalAmountByDateVar)        

        this.setState({
            totalAmountAfterDate:Number(totalAmountAfterDateVarFinal).toFixed(),
            totalAmountAfterRound:Number(totalAmountAfterDateVarFinal).toFixed()
        })
        this.calculateFinalAmount(totalFinalPayableAmount)
        
    }

    
    calculateFinalAmount=(totalFinalPayableAmount)=>{        
        var finalAmount=totalFinalPayableAmount            
        this.setState({
            finalPaymentAfter:Number(finalAmount).toFixed(),           
        })
     }
    
   


    setMiscCharges=(e)=>{
        const{totalMeterRent,totalServiceCharges}=this.state       
       var totalRentalChargesVar=(Number(Number(e.target.value))+(Number(totalMeterRent))+(Number(totalServiceCharges)))      
       this.setState({
            miscCharges:e.target.value,
            totalRentalCharges:Number(totalRentalChargesVar).toFixed()
        })
    }

    setMiscRemarks=(e)=>{
        this.setState({
            miscRemarks:e.target.value
        })
        if(this.state.readOnlyUnpaidArrearsFlag==1){
            this.calculateAmountPayableByDate(this.state.previousAdjustmentBillArrears)
        }
    }

    

    setPreviousAdjustmentAmount=(e)=>{        
        this.setState({
            previousAdjustmentAmount:e.target.value,
            previousAdjustmentBillArrears:''
        })
    }



    setPreviousAdjustmentBillArrears=(e)=>{
        var previousAdjustmentBillArrearsData=e.target.value
        this.setState({
            previousAdjustmentBillArrears:e.target.value
         })
         this.calculateAmountPayableByDate(previousAdjustmentBillArrearsData)
         //this.setTotalPayableAmount(previousAdjustmentBillArrearsData)

    }
    


    setPayableAfterDueAmount=(e)=>{
        
        this.setState({
            payableAfterDueAmount:this.state.totalAmountAfterDate+this.state.totalAmountByDate
        })
    }
    

    /**End Meter Reading */
    handAddNewBill=(event)=>{
        event.preventDefault();
        
        const {billMonth,billNumber,accountNumber,meterStatus,issueDate,dueDate,billType,newReading,newReadingDate,oldReading,oldReadingDate,
               billPeriod,totalFixedCharges,unitsConsumed,totalConsumption,sopTypeConsumerValue,totalMuncipalTax,totalEd,totalInfrCess,
               miscCharges,miscRemarks,previousAdjustmentAmount,previousAdjustmentBillArrears,totalAmountByDate,totalAmountAfterDate,totalServiceCharges,
               totalMeterRent,totalTaxCharges,totalRentalCharges,finalPaymentAfter,preAdjustmentBillArrearsMonth,masterLoadPerMonthRate,loadCharges}=this.state
     
        AddNewBill(billMonth,billNumber,accountNumber,meterStatus,issueDate,dueDate,billType,
                Number(newReading),newReadingDate,Number(oldReading),oldReadingDate,
                billPeriod,Number(totalFixedCharges).toFixed(),unitsConsumed,Number(totalConsumption).toFixed(),
                sopTypeConsumerValue,Number(totalMuncipalTax).toFixed(),Number(totalEd).toFixed(),
                Number(totalInfrCess).toFixed(),Number(miscCharges).toFixed(),miscRemarks,
                Number(previousAdjustmentAmount).toFixed(),Number(previousAdjustmentBillArrears).toFixed(),
                Number(totalAmountByDate).toFixed(),Number(totalAmountAfterDate).toFixed(),
                Number(totalServiceCharges).toFixed(),Number(totalMeterRent).toFixed(),Number(totalTaxCharges).toFixed(),
                Number(totalRentalCharges).toFixed(),Number(finalPaymentAfter).toFixed(),preAdjustmentBillArrearsMonth,
                masterLoadPerMonthRate,loadCharges).
                then(resp=>{
         if(resp){
             if(resp.data==='No'){
                this.setState({
                    message:'Try Again',
                    vColor:'danger',
                    showAlert:true,
                })
             }
             else{
                 const{minDate,dueDate}=this.state
                 this.setState({
                    message:'Succesfully Inserted',
                    vColor:'success',
                   accountNumber:'',
                    name:'',
                    type:'',
                    address:'',        
                    billNumber:'',
                    //address:'',
                    meterRent:'',        
                    //dueDate:'',
                    billType:'Meter-Basis',                
                    consumerAccountDetail:[],    
                    consumerWholeData:[],
                    minDate : minDate,
                    issueDate:minDate,
                    billMonth:minDate,
                    deptBillNo:'',
                    dueDate:dueDate, 
                    masterCharges:[],  
                    meterStatusDetail:[], 
                    //meterStatus:'',
                    masterPerDays:1,
                    masterHoursPerDay:1,
                    perDay:'',
                    masterDFactor:0,
                    masterMuncipal:0,
                    masterED:0,
                    masterInfrCess:0,
                    masterSurcharges:0,
                    masterLoadPerMonthRate:0,
                    billFrom:minDate,
                    billTo:minDate,
                    countMonths:'',
                    newReading:'',
                    newReadingDate:minDate,
                    oldReading:'',
                    oldReadingDate:minDate,
                    ctCharges:1,
                    billPeriod:'',
                    loadCharges:'',
                    unitsConsumed:0,
                    miscCharges:'',
                    miscRemarks:'',
                    totalConsumption:0,
                    totalMuncipalTax:0,
                    totalEd:0,
                    totalInfrCess:0,
                    serviceCharges:0,
                    totalServiceCharges:0,
                    totalMeterRent:0,
                    totalSurcharges:0, 
                    totalAmountByDate:0,   
                    totalAmountAfterDate:0, 
                    totalFixedCharges:0,  
                    totalTaxCharges:0, 
                    SOP:0,
                    sopType:0,
                    sopTypeConsumerValue:0,
                    sopTypeCharges:0,
                    previousAdjustmentAmount:'',
                    previousAdjustmentBillArrears:'',        
                    messageDate:'',        
                    show:false,
                    setShow:false,
                    showAlert:true,
                    totalTaxCharges:0,
                    totalRentalCharges:0,
                    finalPaymentAfter:0,
                    totalAmountAfterRound:0


                 })
                    this.handleViewAllConsumerAccount();
                    this.handleViewChargesMaster();
                    this.handleViewMeterStatus();
                    this.handleViewAllBillRecord(billMonth);
             }
         }
     })
    }
    




    render(){        
    //    console.log(this.state.messageMaster)
    //    console.log(this.state.oldReading)
    //    console.log(this.state.oldReadingDate)
        return(
            <div>
                <Container fluid>
                {/* <Row>
                    <Col style={{textAlign:'center'}}><h3><b><em><u> ADD/ VIEW BILL</u></em></b></h3></Col>
                </Row> */}
               
                <Tab.Container defaultActiveKey="view" transition={false} id="controlled-tab-example">
                    <Row>
                        <Col>
                                <Nav variant="tabs" defaultActiveKey="view">
                                    <Nav.Item>
                                        <Nav.Link eventKey="view">View Bill
                                                <b style={{color:'red',paddingLeft:'5px'}}>
                                                    ({this.state.allBillRecord.length})
                                                </b>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="newBill">New Bill</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                        </Col>
                    </Row>
                    
                    
                    {this.state.message? 
                    <Row>
                        <Col style={{paddingTop:'10px'}}>
                                 <Alert variant={this.state.vColor} show={this.state.showAlert}  onClose={this.setShow} dismissible>
                                            {this.state.message}
                                        </Alert>
                        </Col>
                    </Row>
                         :''}

                  
                    <Row>
                        <Col style={{paddingTop:'30px'}}>
                            <Tab.Content sm={12}>
                                <Tab.Pane eventKey="view">
                                    <Form>  
                                    <Jumbotron style={{height:'10px'}}> 
                                                                                                          
                                      <Row>                                       
                                            <Col>
                                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                                     <Col sm="6">                                                  
                                                        <Form.Label>
                                                         <b>Select Month to Display Record</b>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm="6">
                                                                <MuiPickersUtilsProvider  
                                                                utils={DateFnsUtils}>      
                                                                    <DatePicker    
                                                                        views={["month","year"]}                                                                    
                                                                        value={this.state.viewDataMonthYear} 
                                                                        onChange={this.setViewDataMonthYear}                                                                     
                                                                    />                    
                                                                </MuiPickersUtilsProvider>                                                        
                                                        </Col>
                                                 </Form.Group>
                                           </Col>                                  
                                      </Row>
                                      <Row>
                                        <Col>
                                        <hr></hr>
                                        </Col>
                                    </Row> 
                                      </Jumbotron>
                                      
                                        <Row>
                                            <Col>
                                             <MaterialTable
                                                            name="Bill  List"
                                                            title="Bill List"   
                                    
                                                            columns  ={[
                                                            {title:'S.No',field:'sno'}, 
                                                            {title:'BillMonth ', field:'billMonth'},
                                                            {title:'Bill No ', field:'billNo'},
                                                            {title:'Acccount No.',field:'accNo'},
                                                            {title:'CustomerName',field:'name'},                                                          
                                                            {title:'IssueDate',field:'issueDate'},
                                                            {title:'DueDate',field:'dueDate'},
                                                            {title:'BillType',field:'billType'},
                                                            {title:'NewReading',field:'newReading'},
                                                            {title:'OldReading',field:'oldReading'},
                                                            {title:'AmountPayable',field:'amountPayable'},
                                                            {title:'Surcharge',field:'Surcharge'},
                                                            {title:'AmountPayable After Date',field:'amountPayableAfterDate'},                                                            
                                                            {title:'Status',field:'status'},
                                                                                                            
                                                        ]}
                                            data={
                                                this.state.allBillRecord.map((allBill,id)=>(
                                                    {
                                                        'sno':id+1,
                                                        'billMonth':allBill.billMonth,
                                                        'billNo':allBill.billNo,
                                                        'accNo':allBill.accountNo,
                                                        'name':allBill.name,
                                                        'issueDate':allBill.issueDate,
                                                        'dueDate':allBill.dueDate,
                                                        'billType':allBill.billType,
                                                        'newReading':allBill.NewMeterReading,
                                                        'oldReading':allBill.OldMeterReading,
                                                        'amountPayable':allBill.amountPayable,
                                                        'Surcharge':allBill.amountPayableSurchargeAfter	,
                                                        'amountPayableAfterDate':allBill.amountPayableAfter,
                                                        'status':(allBill.printStatus==0?'Original':'Duplicate')

                                                    }
                                                ))
                                            }
                                            options={{
                                                exportButton: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 20, 50, 100,this.state.allBillRecord.length],
                                                toolbar: true,
                                                paging: true
                                                
                                                }} 
                                                   />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col></Col>
                                        </Row>
                                    </Form>
                                </Tab.Pane>
                            </Tab.Content>

                            <Tab.Content sm={12}>
                                <Tab.Pane eventKey="newBill">
                                <Jumbotron> 

                                <b style={{color:'blue',paddingLeft:'40%'}}>{this.state.messageMaster}</b>
                                <br/>
                                {/* {this.state.messageMaster? 
                    <Row>
                        <Col style={{paddingTop:'10px'}}>
                                 <Alert variant={this.state.vColor} show={this.state.showAlert}  onClose={this.setShow} dismissible>
                                            {this.state.message}
                                        </Alert>
                        </Col>
                    </Row>
                         :''} */}
                                    <Form onSubmit={this.handAddNewBill}>
                                    <Row><Col><p style={{color:'red'}}>All (*) marked fields are mandatory</p></Col></Row>
                                    <Row>
                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                            Bill Month <b style={{color:'red'}}>*</b>
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">                                                                                                              
                                                            <MuiPickersUtilsProvider  utils={DateFnsUtils}>      
                                                                <DatePicker
                                                                        // views={["year"]}
                                                                        views={["month","year"]}
                                                                        value={this.state.billMonth}
                                                                        onChange={this.setBillMonth}                                                                        
                                                                        //minDate={this.state.minDate}
                                                                        // maxDate={this.state.minDate}
                                                                        // value={this.state.issueDate} 
                                                                        // onChange={this.setIssueDate}
                                                                       
                                                                    />                    
                                                            </MuiPickersUtilsProvider>
                                                    
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                            SubDivision 
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">
                                                       <Form.Label >
                                                            S.D.E. (E II) (Elect)
                                                        </Form.Label>
                                                       
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>                                        
                                            <Col>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="6">
                                                       Account Number <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="6">
                                                 
                                                   <Form.Control  as="select" required
                                                                  value={this.state.accountNumber}
                                                                  onChange={this.setAccountNumber}>
                                                                    <option></option>
                                                                    {this.state.consumerAccountDetail.map((accountDetail,id)=>(
                                                                        <option key={id} value={accountDetail.record_id}>{accountDetail.accountNo}</option>
                                                                    ))}                                                                                                       
                                                     </Form.Control>
                                                  </Col>
                                               </Form.Group>
                                            </Col>
                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                            Bill Number
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">
                                                        <Form.Control                                                          
                                                             onChange={this.setBillNumber} 
                                                             value={this.state.billNumber}
                                                             readOnly
                                                             
                                                        />
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                            
                                        </Row>

                                        <Row>
                                          <Col>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="6">
                                                       Name
                                                 </Form.Label>
                                                 <Col sm="6">
                                                    <Form.Control                                                          
                                                             readOnly
                                                             value={this.state.name}
                                                    />
                                                 </Col>
                                               </Form.Group>
                                            </Col>

                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                            Type
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">
                                                        <Form.Control                                                         
                                                                 readOnly
                                                                 value={this.state.type.slice(3)}
                                                        />
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                           
                                            
                                        </Row>                                       

                                        <Row>
                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                            Address
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">
                                                        <Form.Control 
                                                                 readOnly                                                        
                                                                 value={this.state.address}
                                                        />
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                            <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                               Meter Status <b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control as="select" required
                                                                value={this.state.meterStatus}
                                                                onChange={this.setMeterStatus} > 

                                                            <option></option>
                                                            {this.state.meterStatusDetail.map((meterStatusD,id)=>(
                                                                        <option key={id} value={meterStatusD.meterStatus_id}>{meterStatusD.meterSymbol}-{meterStatusD.meterStatus}</option>
                                                                    ))}                                                                                                  
                                                            </Form.Control>
                                            </Col>
                                        </Form.Group>
                                     </Col>
                                        </Row>

                                        {/* <Row>
                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                            Issue Date <b style={{color:'red'}}>*</b>
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">                                                                                                              
                                                            <MuiPickersUtilsProvider  utils={DateFnsUtils}>      
                                                                <DatePicker  
                                                                        minDate={this.state.minDate}
                                                                        maxDate={this.state.minDate}
                                                                        value={this.state.issueDate} 
                                                                        onChange={this.setIssueDate}
                                                                       
                                                                    />                    
                                                            </MuiPickersUtilsProvider>
                                                    
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                            Due Date <b style={{color:'red'}}>*</b>
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">
                                                            <MuiPickersUtilsProvider  
                                                                        utils={DateFnsUtils}>      
                                                                    <DatePicker                                                                       
                                                                         minDate={this.state.dueDate}
                                                                         maxDate={this.state.dueDate}
                                                                         value={this.state.dueDate} 
                                                                         onChange={this.setDueDate}
                                                                    />                    
                                                            </MuiPickersUtilsProvider>                                                        
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                        </Row> */}

                                        <Row>
                                            <Col>
                                                 <hr></hr>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Col sm="4">                                                  
                                                        <Form.Label >
                                                            Bill Type <b style={{color:'red'}}>*</b>
                                                        </Form.Label>
                                                   </Col>
                                                   {this.state.billFlag==1   ? 
                                                      <Col sm="8">  
                                                      
                                                      {/* {this.state.billType} */}
                                                      <div onChange={this.setBillType}>
                                                   <div style={{float:'left',width:'50%'}}>
                                                        <Form.Check
                                                            inline                                                            
                                                            label="Load Basis Reading"
                                                            type="radio"
                                                            name="billType" 
                                                            value="Load-Basis"
                                                            checked={this.state.billType==="Load-Basis"}
                                                            // onChange={this.setBillType} 
                                                            required                                                         
                                                            />
                                                   </div>
                                                   <div style={{float:'right',width:'50%'}}>
                                                        <Form.Check
                                                            inline                                                            
                                                            label="LYSM"
                                                            type="radio"      
                                                            name="billType"
                                                            value="LYSM"
                                                            checked={this.state.billType==="LYSM"}
                                                                                                                   
                                                            />
                                                   </div>
                                                   </div>


                                                      
                                                      
                                                      </Col>  :
                                                   <Col sm="8">
                                                       <div onChange={this.setBillType}>
                                                   <div style={{float:'left',width:'50%'}}>
                                                        <Form.Check
                                                            inline                                                            
                                                            label="Load Basis Reading"
                                                            type="radio"
                                                            name="billType" 
                                                            value="Load-Basis"
                                                            checked={this.state.billType==="Load-Basis"}
                                                            // onChange={this.setBillType} 
                                                            required                                                         
                                                            />
                                                   </div>
                                                   <div style={{float:'right',width:'50%'}}>
                                                        <Form.Check
                                                            inline                                                            
                                                            label="Meter Basis Reading"
                                                            type="radio"      
                                                            name="billType"
                                                            value="Meter-Basis"
                                                            checked={this.state.billType==="Meter-Basis"}
                                                                                                                   
                                                            />
                                                   </div>
                                                   </div>
                                                   </Col>
                                                }
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                       

                                        <Row >
                                            <Col>
                                                 <hr></hr>
                                            </Col>
                                        </Row>
                                        
                                                                                  
                                    {this.state.billType=='Load-Basis'?(
                                        <p>
                                       <Row>
                                       {/* <Col>
                                         <Form.Group as={Row} controlId="formPlaintextEmail">
                                             <Col sm="6">                                                  
                                                   <Form.Label >
                                                    Days Per Day
                                                   </Form.Label>
                                              </Col>
                                              <Col sm="6">
                                                   <Form.Control 
                                                   value={this.state.perDay}
                                                   onChange={this.setPerDay}
                                                   type={'number'}
                                                   min={1}
                                                   max={31}
                                                   step={1}
                                                                                                              
                                                   />
                                            </Col>
                                         </Form.Group>
                                       </Col> */}
                                       <Col>
                                         <Form.Group as={Row} controlId="formPlaintextEmail">
                                             <Col sm="6">                                                  
                                                   <Form.Label >
                                                       D-Factor (aL1)
                                                   </Form.Label>
                                              </Col>
                                              <Col sm="6">
                                                   <Form.Control
                                                      value={this.state.masterDFactor} 
                                                        readOnly   
                                                   />
                                            </Col>
                                         </Form.Group>
                                       </Col>
                                       <Col>
                                         <Form.Group as={Row} controlId="formPlaintextEmail">
                                             <Col sm="6">                                                  
                                                   <Form.Label >
                                                       Hours Per Day(aL2)
                                                   </Form.Label>
                                              </Col>
                                              <Col sm="6">
                                                    <Form.Control 
                                                        value={this.state.masterHoursPerDay}
                                                        readOnly        
                                                                />
                                                  
                                            </Col>
                                         </Form.Group>
                                       </Col>
                                   </Row>
                                   <Row>
                                   <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                             OldReading Date<b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                                            <MuiPickersUtilsProvider  
                                                                        utils={DateFnsUtils}>      
                                                                    <DatePicker                                                                        
                                                                    value={this.state.oldReadingDate} 
                                                                    onChange={this.setOldReadingDate}                                                                     
                                                                    />                    
                                                            </MuiPickersUtilsProvider>                                                        
                                                 </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                NewReading Date<b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                                            <MuiPickersUtilsProvider  
                                                                        utils={DateFnsUtils}>      
                                                                    <DatePicker  
                                                                     value={this.state.newReadingDate} 
                                                                     onChange={this.setNewReadingDate}                                                                                                                                                                                                                      
                                                                    />                    
                                                            </MuiPickersUtilsProvider>                                                        
                                                 </Col>
                                  </Form.Group>
                                </Col>
                                       
                                       {/* <Col>
                                         <Form.Group as={Row} controlId="formPlaintextEmail">
                                             <Col sm="6">                                                  
                                                   <Form.Label >
                                                       D-Factor
                                                   </Form.Label>
                                              </Col>
                                              <Col sm="6">
                                                   <Form.Control
                                                      value={this.state.masterDFactor} 
                                                        readOnly   
                                                   />
                                            </Col>
                                         </Form.Group>
                                       </Col> */}
                                       {/* <Col>
                                         <Form.Group as={Row} controlId="formPlaintextEmail">
                                             <Col sm="6">                                                  
                                                   <Form.Label >
                                                   Total Month/s
                                                   </Form.Label>
                                              </Col>
                                              <Col sm="6">
                                                    <Form.Control 
                                                        value={this.state.countMonths}        
                                                                />
                                                  
                                            </Col>
                                         </Form.Group>
                                       </Col> */}
                                       
                                   </Row>
                                   {this.state.messageDate? 
                                            <Row>
                                                <Col style={{paddingTop:'5px'}}>
                                                <Alert variant={this.state.vColor} show={this.state.showAlert}  onClose={this.setShow} dismissible>
                                                            {this.state.messageDate}
                                                                </Alert>
                                                </Col>
                                            </Row>
                                                :''}

                                    {/* <Row>
                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                        From Date
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">                                                                                                              
                                                            <MuiPickersUtilsProvider  utils={DateFnsUtils}>      
                                                                <DatePicker 
                                                                    value={this.state.billFrom} 
                                                                    onChange={this.setBillFrom} 
                                                                    dateFormat="DD/MM/YYYY"                                                               
                                                                    />                    
                                                            </MuiPickersUtilsProvider>
                                                    
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                            <Col>
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                  <Col sm="6">                                                  
                                                        <Form.Label >
                                                            To Date
                                                        </Form.Label>
                                                   </Col>
                                                   <Col sm="6">
                                                            <MuiPickersUtilsProvider  
                                                                        utils={DateFnsUtils}>      
                                                                    <DatePicker  
                                                                     value={this.state.billTo} 
                                                                     onChange={this.setBillTo}                                                                                                                                          
                                                                    />                    
                                                            </MuiPickersUtilsProvider>                                                        
                                                 </Col>
                                              </Form.Group>
                                            </Col>
                                        </Row> */}
                                  
                                </p>
                                ):""}

                                {this.state.billType=='Meter-Basis'?(
                                <p>
                                <Row>
                                    <Col>
                                       <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="6">                                                  
                                            <Form.Label >
                                            Old Reading<b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                            value={this.state.oldReading}
                                            onChange={this.setOldReading}
                                            readOnly={this.state.oldValueFlag==1}
                                            type={'number'}
                                            step={1}                                                    
                                            />
                                       </Col>
                                      </Form.Group>
                                   </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                New Reading<b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                            value={this.state.newReading}
                                            onChange={this.setNewReading}
                                            type={'number'}
                                            step={1}     
                                            required                                               
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                              <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                             OldReading Date<b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                                            <MuiPickersUtilsProvider  
                                                                        utils={DateFnsUtils}>      
                                                                    <DatePicker                                                                        
                                                                    value={this.state.oldReadingDate}
                                                                    readOnly={this.state.oldValueFlag==1} 
                                                                    onChange={this.setOldReadingDate}                                                                     
                                                                    />                    
                                                            </MuiPickersUtilsProvider>                                                        
                                                 </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                NewReading Date <b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                                            <MuiPickersUtilsProvider  
                                                                        utils={DateFnsUtils}>      
                                                                    <DatePicker  
                                                                     value={this.state.newReadingDate} 
                                                                     onChange={this.setNewReadingDate} 
                                                                     //dateFormat="DD/MM/YYYY"
                                                                     //onChange={}
                                                                                                                                                
                                                                    />                    
                                                            </MuiPickersUtilsProvider>                                                        
                                                 </Col>
                                  </Form.Group>
                                </Col>
                               
                                
                            </Row> 
                            
                            {this.state.messageDate? 
                                            <Row>
                                                <Col style={{paddingTop:'5px'}}>
                                                <Alert variant={this.state.vColor} show={this.state.showAlert}  onClose={this.setShow} dismissible>
                                                                    {this.state.messageDate}
                                                                </Alert>
                                                </Col>
                                            </Row>
                                                :''}

                         </p>
                                ):""}

                                <Row>
                                    <Col>
                                       <hr></hr> 
                                    </Col>
                                </Row>
                                
                           {this.state.billType?<p>
                            <fieldset style={{border:'3px solid',padding:'5px',paddingTop:'2%'}}>                                   
                                       <legend>Fixed Charges :</legend> 
                            <Row>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                Load 
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                                    // value={this.state.loadCharges}
                                                    value={this.state.loadChargesMaster}
                                                    readOnly
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                             Rate/Kw/Month (a) <i style={{color:'red'}}>*varies acc. to load</i>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                                precision={2}
                                                readOnly
                                                value={this.state.masterLoadPerMonthRate}
                                             />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                               <Col>
                                   <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                Bill Period (b)                                       
                                            </Form.Label>
                                       </Col>
                                     <Col sm="6">
                                            <Form.Control                                             
                                            value={this.state.billPeriod}
                                            //onChange={}
                                            type={'number'}
                                            step={1}  
                                            readOnly  
                                            required                                                
                                            />
                                     </Col>
                                     </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="6">                                                  
                                            <Form.Label >
                                                Load (c) <i style={{color:'red'}}>*{this.state.loadChargesPercentage} of Load</i>
                                            </Form.Label>
                                        </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                                    value={this.state.loadCharges}
                                                    readOnly
                                            />
                                        </Col>
                                  </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Col sm="6">                                                  
                                                    <Form.Label style={{fontWeight:'bold'}} >
                                                    Total Fixed Charges (a*b*c*12/365) Rs.
                                                    </Form.Label>
                                            </Col>
                                            <Col sm="6">
                                                    <Form.Control                                                      
                                                    value={(this.state.totalFixedCharges)}
                                                        readOnly
                                                    
                                                                />
                                                
                                            </Col>
                                        </Form.Group>
                                </Col>
                            </Row>


                            </fieldset>
                               <fieldset style={{border:'3px solid',padding:'5px',paddingTop:'2%'}}>                                   
                                       <legend>Energy Charges:</legend>                         
                            <Row>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                Units Consumed (d)
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                                    value={this.state.unitsConsumed}
                                                    readOnly
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                             CT Ratio (e)
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                                precision={2}
                                                readOnly
                                                value={this.state.ctCharges}
                                                         />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                Total Consumption  
                                                {this.state.billType==='Meter-Basis'?'(f=d*e)':this.state.billType==='Load-Basis'? '(f=a*aL1*c*aL2)':''}
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                                    //value={(this.state.totalConsumption).toFixed()}
                                                   value={this.state.totalConsumption}
                                                    readOnly
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                            Rate/kWh (g)
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                                // precision={2}
                                                 readOnly
                                                 value={this.state.sopTypeCharges}
                                                         />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                 <Form.Group as={Row} controlId="formPlaintextEmail">
                                     <Col sm="6">
                                         <Form.Label style={{fontWeight:'bold'}}>
                                         Total Energy Charges (f*g)
                                         </Form.Label>                                         
                                     </Col>
                                     <Col sm="6">
                                     <Form.Control 
                                                precision={2}
                                                readOnly
                                                //value={(this.state.sopTypeConsumerValue).toFixed()}
                                                 value={this.state.sopTypeConsumerValue}
                                                         />

                                     </Col>

                                 </Form.Group>
                                </Col>
                            </Row>
                            </fieldset>
                            <Row></Row>
                            <fieldset style={{border:'3px solid',padding:'5px',paddingTop:'2%'}}>                                   
                                       <legend>Taxes:</legend>
                            <Row>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                Muncipal Tax (h)
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control
                                            precision={2}
                                            readOnly
                                            //value={(this.state.totalMuncipalTax).toFixed()} 
                                            value={this.state.totalMuncipalTax}
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                             E.D. (i)
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                                //value={(this.state.totalEd).toFixed()}
                                               value={this.state.totalEd}
                                                step={.01}
                                                readOnly 
                                                         />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                                Service Charges (j)
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                            value={this.state.totalServiceCharges}
                                            readOnly
                                            step={.01}                                                    
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                             IDF (k)
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                             //value={(this.state.totalInfrCess).toFixed()}
                                             value={this.state.totalInfrCess}
                                             readOnly
                                             step={.01}                                                         
                                                         />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                 <Form.Group as={Row} controlId="formPlaintextEmail">
                                     <Col sm="6">
                                         <Form.Label style={{fontWeight:'bold'}}>
                                         Total Taxes (h+i+k)
                                         </Form.Label>                                         
                                     </Col>
                                     <Col sm="6">
                                     <Form.Control 
                                                precision={2}
                                                readOnly
                                                //value={(this.state.sopTypeConsumerValue).toFixed()}
                                                 value={this.state.totalTaxCharges}
                                                         />

                                     </Col>

                                 </Form.Group>
                                </Col>
                            </Row>
                            </fieldset>
                            <fieldset style={{border:'3px solid',padding:'5px',paddingTop:'2%'}}>                                   
                                       <legend>Miscelleanous Charges:</legend>
                            <Row>
                            <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                              Meter Rent (l)
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                               value={this.state.totalMeterRent}
                                               readOnly
                                               step={.01}           
                                                         />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                               Surcharges
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                            readOnly
                                            value={this.state.totalSurcharges}
                                                    
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>                                
                            </Row>
                            <Row>
                            <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                               Miscelleanous Charges (m) <b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                            //readOnly
                                            required
                                            onChange={this.setMiscCharges}
                                            value={this.state.miscCharges}
                                                    
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>   
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                              Misc Remarks<b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                            //readOnly
                                            required
                                            onChange={this.setMiscRemarks}
                                            value={this.state.miscRemarks}
                                                    
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>   
                            </Row>
                            <Row>
                                <Col>
                                 <Form.Group as={Row} controlId="formPlaintextEmail">
                                     <Col sm="6">
                                         <Form.Label style={{fontWeight:'bold'}}>
                                         Total Rental (j+l+m)
                                         </Form.Label>                                         
                                     </Col>
                                     <Col sm="6">
                                     <Form.Control 
                                                precision={2}
                                                readOnly
                                                //value={(this.state.sopTypeConsumerValue).toFixed()}
                                                 value={this.state.totalRentalCharges}
                                                         />

                                     </Col>

                                 </Form.Group>
                                </Col>
                            </Row>
                            </fieldset>
                            <fieldset style={{border:'3px solid',padding:'5px',paddingTop:'2%'}}>                                   
                                       <legend>Previous Amount:</legend>
                                       <Row>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                               Adjustment Amount<b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                            //value={this.state.previousAdjustmentAmount!=''?(this.state.previousAdjustmentAmount).toFixed():this.state.previousAdjustmentAmount}
                                            value={this.state.previousAdjustmentAmount}
                                            readOnly={this.state.readOnlyAdjustmentAmountFlag==1}
                                            onChange={this.setPreviousAdjustmentAmount}
                                            required
                                            //step={.01}
                                                    
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                            Unpaid Bill Arrears<b style={{color:'red'}}>*</b>
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                               //value={this.state.previousAdjustmentBillArrears!=''?(this.state.previousAdjustmentBillArrears).toFixed():this.state.previousAdjustmentBillArrears}
                                               //value={(this.state.previousAdjustmentBillArrears).toFixed()}
                                              value={this.state.previousAdjustmentBillArrears}
                                               readOnly={this.state.readOnlyUnpaidArrearsFlag==1}   
                                               onChange={this.setPreviousAdjustmentBillArrears}
                                                required
                                                         />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col>
                            </Row>

                            </fieldset>
                            <fieldset style={{border:'3px solid',padding:'5px',paddingTop:'2%'}}>                                   
                                       <legend>Amount:</legend>

                            <Row>
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                               Amount Payable By Due Date 
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control 
                                            value={this.state.totalAmountByDate}                                          
                                            readOnly                                 
                                                    
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col>
                                {/* <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                            Final Amount Payable By Due Date 
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                             value={this.state.totalAmountByDateTenRoundOff}                                               
                                                readOnly                                                        
                                                         />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col> */}
                            </Row> 
                            <Row>
                                {/* <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                            Surcharges of unpaid bill
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                            <Form.Control
                                             value={this.state.totalAmountAfterDate} 
                                            // value={(this.state.totalAmountAfterDate).toFixed()}                                            
                                            readOnly
                                            step={.01}
                                                    
                                            />
                                     </Col>
                                  </Form.Group>
                                </Col> */}
                                <Col>
                                  <Form.Group as={Row} controlId="formPlaintextEmail">
                                      <Col sm="6">                                                  
                                            <Form.Label >
                                            Final Surcharges of unpaid bill
                                            </Form.Label>
                                       </Col>
                                       <Col sm="6">
                                             <Form.Control 
                                             value={this.state.totalAmountAfterRound}                                             
                                             readOnly                                                        
                                                         />
                                           
                                     </Col>
                                  </Form.Group>
                                </Col>
                            </Row> 

                            <Row>
                                
                                <Col sm="6">
                                            <Form.Label >
                                            Amount Payable After Due Date
                                            </Form.Label>
                                </Col>
                                <Col sm="6">
                                    <Form.Control  
                                   // value={this.state.totalAmountAfterDate}                              
                                     value={this.state.finalPaymentAfter }
                                            //       Number(this.state.totalFixedCharges) +
                                            //       Number(this.state.miscCharges)}                         
                                            readOnly                                                        
                                        />
                                </Col>

                                {/* <Col sm="6">
                                            <Form.Label >
                                            Amount Payable After Due Date(Round-Off)
                                            </Form.Label>
                                </Col>
                                <Col sm="6">
                                <Form.Control                                
                                    value={this.state.finalPaymentAfterRound}                                
                                    readOnly                                                        
                                    />

                                </Col> */}
                            </Row>
                            </fieldset>                         
                             
                            <Button  size="sm" style={{marginLeft:'40%',width:'250px',marginTop:'6%'}}  type="submit" 
                            variant='outline-info'

                             >
                                            Submit
                            </Button>
                            
                            </p>:""}  
                                    </Form>
                                    </Jumbotron>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                </Tab.Container>
                </Container>
            </div>
        )
    }
 }