import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import MaterialTable from 'material-table';
import Button from "react-bootstrap/Button";
import AdminViewAllBillRecord from '../methods/AdminViewAllBillRecord';
import DownloadBill from '../methods/DownloadBill';
import DownloadReceipt from '../methods/DownloadReceipt';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Alert from "react-bootstrap/Alert";
import ViewMonthlyBillRecord from '../methods/ViewMonthlyBillRecord';
import ViewMonthlyPaidBill from '../methods/ViewMonthlyPaidBill';
import DownloadPaymentReceipt from '../methods/DownloadPaymentReceipt';

import {
    
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";


export default class DownloadApproveReceipt extends React.Component{
    constructor(props){
        super(props);
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
        const newDate = new Date(Date.now() + 15 * 24*60*60*1000);

       this.state={        
       message:'',       
       vColor:'',
       show:false,
       setShow:false,
       showAlert:false,
       allPaidBillRecord:[],
       billMonth:minDate,
       //vColor:''
      // showAlert:false
       
   }
}

    componentDidMount=()=>{
           
     //this.handleAdminViewAllBillRecord(this.state.billMonth);
     this.handleViewMonthlyPaidBill(this.state.billMonth);
      
}

setBillMonth=(e)=>{
    this.setState({
        billMonth:e
    })    
       this.handleViewMonthlyPaidBill(e);
}
handleViewMonthlyPaidBill=(e)=>{
    ViewMonthlyPaidBill(e).then(resp=>{
        if(resp){
            console.log(resp.data)
            if(resp.data=='NO'){
                this.setState({
                    message:'No Payment for this month',
                    vColor:'danger',
                    showAlert:true,
                    allPaidBillRecord:[]
                })
            }
            else{
                this.setState({                   
                    showAlert:false,
                    allPaidBillRecord:resp.data
                })

            }
        }
    })
}

//handleAdminViewAllBillRecord=(e)=>{
    handleViewMonthlyBillRecord=(e)=>{
    //console.log(e);
   //AdminViewAllBillRecord(e).then(resp=>{
    ViewMonthlyBillRecord(e).then(resp=>{
       if(resp){
           if(resp.data==='No'){
               this.setState({
                   message:'No Record Found',
                   vColor:'danger',
                   showAlert:true,
                   allBillRecord:[]
               })
           }
           else{
               this.setState({
                   // message:'',
                   // vColor:'',
                   showAlert:false,
                  allBillRecord:resp.data
               })
           }
       }
       else{

           }
   })
}
setShow=()=>{
    this.setState({
        showAlert:false
    })
}

// handleDownloadReceipt=(billrecord_id)=>{   
//     DownloadReceipt(billrecord_id,this.state.billMonth).then(resp=>{
//       const url = window.URL.createObjectURL(new Blob([resp.data]));
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', 'BillReciept.pdf');
//       document.body.appendChild(link);
//       link.click();
//       }).catch(error=>{        
//       });
// }

handleDownloadPaymentReceipt=(payment_id)=>{   
    // console.log(payment_id)
    DownloadPaymentReceipt(payment_id).then(resp=>{
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'BillReciept.pdf');
      document.body.appendChild(link);
      link.click();
      }).catch(error=>{        
      });
}



    render(){
        console.log(this.state.allPaidBillRecord)
        return(
            <div>
                  <Container fluid>
                {/* <Row>
                        <Col style={{textAlign:'center'}}>
                            <h3><b>DOWNLOAD RECEIPT</b></h3>
                        </Col>
                </Row> */}
                <Alert show={this.state.showAlert} variant={this.state.vColor} onClose={this.setShow} dismissible>
                         {this.state.message}
                </Alert>
               
                
            

                <Row>

                                                        <Col>   
                                                        <Jumbotron>         
                                                            <h3>Download the  Receipt of Paid Selected Month Bill</h3>                                             
                                                            <MuiPickersUtilsProvider  utils={DateFnsUtils}>      
                                                                <DatePicker
                                                                        label='Select the Month'
                                                                        views={["month","year"]}
                                                                        value={this.state.billMonth}
                                                                        onChange={this.setBillMonth}                                                                        
                                                                                                                                     
                                                                    />                    
                                                            </MuiPickersUtilsProvider>
                                                            
                                                            </Jumbotron>
                                                            </Col>

                                                
                </Row>
                <Row>
                    <Col>
                        <MaterialTable
                                                            name="Download Receipt of Paid Bills"
                                                                title="Download Receipt of Paid Bills"   
                                        
                                                                columns  ={[
                                                                {title:'S.No',field:'sno'}, 
                                                                {title:'BillMonth ', field:'billMonth'},
                                                                {title:'Bill No ', field:'billNo'},
                                                                {title:'Acccount No.',field:'accNo'},
                                                                {title:'CustomerName',field:'name'},
                                                                {title:'ReceiptNumber',field:'receiptNumber'},
                                                                {title:'PaidDate',field:'paidDate'},
                                                                {title:'Download',field:'action'}
                                                                ]}
                                                            
                                                            
                                                            data={
                                                                this.state.allPaidBillRecord.map((allPaidBill,id)=>(
                                                                    {
                                                                        'sno':id+1,
                                                                        'billMonth':allPaidBill.billMonth,
                                                                        'billNo':allPaidBill.billNo,
                                                                        'accNo':allPaidBill.accountNo,
                                                                        'name':allPaidBill.name,
                                                                        'receiptNumber':allPaidBill.billreceipt_id?allPaidBill.billreceipt_id:<p style={{color:'red'}}>Pending</p>,
                                                                        'paidDate':allPaidBill.paymentDate?allPaidBill.paymentDate:<p style={{color:'red'}}>Pending</p>,
                                                                        'action':allPaidBill.billreceipt_id?(
                                                                            <Button size="sm"  
                                                                                variant='outline-info'  
                                                                                onClick={this.handleDownloadPaymentReceipt.bind(this,allPaidBill.payment_id)}>
                                                                                Download Receipt
                                                                            </Button>):<p style={{color:'red'}}>Not Available Yet</p>
                                                       
                                                                    }
                                                                ))}

                                                                options={{
                                                                    exportButton: true,
                                                                    pageSize: 5,
                                                                    pageSizeOptions: [5, 10, 20, 50, 100,this.state.allPaidBillRecord.length],
                                                                    toolbar: true,
                                                                    paging: true
                                                                    
                                                                    }} 
                                                                />
                                                                </Col>
                                                                </Row>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                
                                                             
               
                   
                </Container>
            </div>
        );
    }
}


