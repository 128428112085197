import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import logo from '../logo.jpg';
import GlobalCharges from "./GlobalCharges";
import AdminDashboard from "./AdminDashboard";
import ApproveConsumer from "./ApproveConsumer";
import ApproveBill from "./ApproveBill";
//import JEBillPayment from "./ApproveBill";
import DownloadBill from "./DownloadBill";
import AdminLogs from "./AdminLogs";
import JEBillPayment from "./JEBillPayment";
import AdminReports from "./AdminReports";

export default class AdminPanel extends React.Component { 
    logoutPanel=()=>{
        this.props.history.push(`/`)
    }

    render(){
        const textSize = window.innerWidth < 550 ? '0.6rem' : '2rem';
        return(<div>
            
            <Container fluid>
            <Row>
            <Col  >
                <Navbar fixed="top" bg="success" className="justify-content-between">
                <Navbar.Brand>
                  <Row >
                  <Col  >
                <Navbar fixed="top"  style={{background: 'linear-gradient(to right, green, #ffff88)'}} className="justify-content-between">
                <Navbar.Brand>
                  <Image src={logo} width={100} height={100} roundedCircle />{' '}
                              <span style={{fontSize: textSize, color:'white'}}>PAU Online Electricity Bill Portal</span>
                        </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                    <span style={{fontSize: '1.5vw'}}>Punjab Agricultural University, Ludhiana</span>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Navbar>     
            </Col>

                  
                    
                  </Row>                           
                </Navbar.Brand>
               
              </Navbar>     
            </Col>
          </Row>

          <br/><br/>
          <div style={{marginTop: '85px'}}></div>


                {/* <Row>
                    <Col>
                    <Navbar fixed="top" bg="success" className="justify-content-between">
                        <Navbar.Brand>
                        <Image src={logo} width={60} height={60} roundedCircle />{' '}
                              <span style={{fontSize: textSize,color:'white'}}> PAU Xen Electricity Bill Generation</span>
                        </Navbar.Brand>
                        <Navbar.Collapse className="justify-content-end">
                            <Navbar.Text>
                                <span style={{fontSize: '1.5vw',color:'white'}}>Welcome, Admin!</span>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Navbar>     
                    </Col>
                </Row> */}



                      <div 
                    //   style={{paddingTop:'100px'}}
                      >
                        
                <Row>
                    <Col><br/>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="adminDashboard" unmountOnExit>
        
                     
                            <Row>
                                <Col sm={2} style={{background:'#F5F5F5'}}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                    <Nav.Link eventKey="adminDashboard">Admin Dashboard</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="globalCharges">Add Global Charges</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                    <Nav.Link eventKey="newConsumer">Approve Consumer</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                    <Nav.Link eventKey="newBill">Approve Bill</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                    <Nav.Link eventKey="downloadBill">Download Bill</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                    <Nav.Link eventKey="report">Download Reports</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                    <Nav.Link eventKey="adminLogs">Logs</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                    <Nav.Link eventKey="logout" onClick={this.logoutPanel}> Logout</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                </Col>
                                <Col sm={10}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="adminDashboard">
                                            <AdminDashboard />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="globalCharges">
                                            <GlobalCharges />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="newConsumer">
                                    <ApproveConsumer />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="newBill">
                                    <ApproveBill />
                                    {/* <JEBillPayment/> */}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="downloadBill">
                                    <DownloadBill />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="report">
                                    <AdminReports />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="adminLogs">
                                    <AdminLogs />
                                    </Tab.Pane>

                                </Tab.Content>
                                </Col>
                            </Row>
                            </Tab.Container>
                    
                    </Col>
                </Row>
</div>
                <Row>
                    <Col>
                    <Navbar size='sm' fixed="bottom" bg="secondary">
                       <span style={{fontSize:'0.7em'}}>For Discrepency/Query Contact the Project Managers: Er. Suruchi Badhan (Programmer, DEEIT) or Er. Simranpreet Kaur (Programmer, DEEIT)</span>
                    </Navbar>
                    </Col>
                </Row>
                
            </Container>
            
            </div>)




    }
}