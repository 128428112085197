import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Alert from 'react-bootstrap/Alert';
//import Badge from 'react-bootstrap/Badge';
import "./Login.css";
import logo from '../logo.jpg';
import LoginAuthentication from '../methods/LoginAuthentication';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';


export default class Login extends React.Component {
  state = {
    username: '',
    password: '',
    showAlert: false,
    flag:''
  }

  validateForm() {
    const { username, password } = this.state;
    return username.length > 0 && password.length > 0;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;

    LoginAuthentication(username, password).then(resp=>{
      if(resp){
        if(resp.data==='No'){
         this.setState({
           showAlert: true,
           flag:'Invalid Username or Password.'
           })
       }
       else if(resp.data.access_id===1){               
              this.setState({
                 showAlert: true,
                 flag:'',
                })
        this.props.history.push(`/JePanel`) 
           
        }
        else if(resp.data.access_id===2){ 
               this.setState({
                      showAlert: true,
                      flag:'',
                    })
        this.props.history.push(`/AdminPanel`)             
        }   
     }
  })    
   
  }

  setPassword = (e) => {
    this.setState({
      password: e,
      showAlert: false
    })

  }

  setUsername = (e) => {
    this.setState({
      username: e,
      showAlert: false
    })

  }

  render() {
    const { username, password, showAlert } = this.state;
    const textSize = window.innerWidth < 550 ? '0.6rem' : '2rem';
    return (
      <div className="Login">
        <Container fluid>
          <Row>
            <Col  >
                <Navbar fixed="top" bg="success" className="justify-content-between">
                <Navbar.Brand>
                  <Row >
                  <Col  >
                <Navbar fixed="top"  style={{background: 'linear-gradient(to right, green, #ffff88)'}} className="justify-content-between">
                <Navbar.Brand>
                  <Image src={logo} width={100} height={100} roundedCircle />{' '}
                              <span style={{fontSize: textSize, color:'white'}}>PAU Online Electricity Bill Portal</span>
                        </Navbar.Brand>
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                    <span style={{fontSize: '1.5vw'}}>Punjab Agricultural University, Ludhiana</span>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Navbar>     
            </Col>

                  
                    
                  </Row>                           
                </Navbar.Brand>
               
              </Navbar>     
            </Col>
          </Row>
          <br/><br/>
          <div style={{marginTop: '65px'}}></div>
          <Row>
            <Col></Col>
            <Col xs={12} md={4} >
              <Jumbotron  
              // variant="outline-success" 
              style={{background: 'linear-gradient(to left, #fcfff0, #ffffdd)'}}
              >
              {/* <Alert variant="success" block> */}
                 <Row>
                   <Col>
                   <h2 > <u block>Login to Your Account</u>  </h2>
                   </Col>
                 </Row>
               
                <br /> <br />
                <Form onSubmit={this.handleSubmit} >
                    <Row>
                      <Col>
                          <InputGroup  size="md">                 
                        <InputGroup.Text id="basic-addon1">
                          {/* <FontAwesomeIcon icon={faAddressCard} color="blue" />  */}
                          <FontAwesomeIcon icon={faEnvelope} size="md" color="green" />
                          </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          // type="email"
                          type="text"
                          value={username}
                          placeholder='Enter username'
                          onChange={(e) => this.setUsername(e.target.value)}
                        />
                  </InputGroup>
                      
                      </Col>
                    </Row>
                    <br />
                
                   <Row>
                    <Col>
                    <InputGroup  size="md" >                 
                    <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faLock} size="md" color="green" />
                    </InputGroup.Text>
                    <Form.Control
                      type="password"
                      value={password}
                      placeholder='Enter password'
                      onChange={(e) => this.setPassword(e.target.value)}
                    />
                  </InputGroup>

                    
                    </Col>
                   </Row>
                   <br /><br/>
                <Row>
                  <Col>
                      <Button 
                          block
                          variant="outline-success"
                          size="md" 
                          type="submit" 
                          disabled={!this.validateForm()}
                      >
                    LOGIN  
                    </Button>
                  </Col>
                </Row>
                <br /> 
                 
                  

                  <Alert show={showAlert} variant="danger">
                    {this.state.flag}
                    
                 </Alert>
                </Form>
                {/* </Alert> */}
              </Jumbotron>
            </Col>
            <Col></Col>
          </Row>

          <Row>

            <Navbar size='sm' fixed="bottom" bg="secondary">
              <Navbar.Brand style={{fontSize: '1vw',color:'white'}}>
                Maintained and Hosted By: Department of Electrical Engineering and IT
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text 
                //style={screensize < 200 ? {fontSize: '1.5vw'}: {fontSize: '2vw'}}
                style={{color:'white'}}
                >
                  Disclaimer and Privacy:<a href="https://www.pau.edu">
                    <b style={{color:'white'}}>https://www.pau.edu</b></a>
                </Navbar.Text>
              </Navbar.Collapse>

            </Navbar>
          </Row>
        </Container>

        <br />


      </div>
    );
  }//render
}//class Login