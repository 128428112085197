import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert'
// import ViewConsumerRecordStatus from '../methods/ViewConsumerRecordStatus';
import AdminDashboard from "../methods/AdminDashboard";

export default class XenDashboard extends React.Component{
    state={
        terminate:0,
        pending:0,
        approve:0,
        billCountMonth:0,
        billCountTotal:0,
        billCountPaid:0,
        amountMonth:0,
        amountTotal:0,
        amountAdvance:0

    }
    componentDidMount=()=>{
        this.viewConsumerRecordStatus()
    }
    viewConsumerRecordStatus=()=>{
        AdminDashboard().then(resp=>{
            this.setState({
                terminate:resp.data.terminate,
                pending:resp.data.pending,
                approve:resp.data.approve,
                billCountMonth:resp.data.billCountMonth,
                billCountTotal:resp.data.billCountTotal,
                billCountPaid:resp.data.billCountPaid,
                amountMonth:resp.data.amountMonth,
                amountTotal:resp.data.amountTotal,
                amountAdvance:resp.data.amountAdvance
            })
        })

    }
    render(){
        return(
            <div>
                <Container fluid>
                    <Row>
                        <Col style={{textAlign:'center'}}>
                            {/* <h3><b> ADMIN DASHBOARD</b></h3> */}
                        </Col>
                    </Row>
                    
                    <Row>
                        {/* <Col></Col> */}
                        <Col  xs={4} style={{textAlign:'center'}} >
                            
                        <Card
                              border="info" 
                              text="info" 
                             
                            >
                                <Card.Body> 
                                    <Alert  variant="info" text="info" ><b>Consumer Information</b></Alert>
                                     <Card.Title>
                            
                                    Number of Pending Consumers: {this.state.pending}
                                </Card.Title>
    
                                <Card.Text>
                                    Number of Approved Consumers: {this.state.approve}<br/>
                                    Number of Terminated Consumers: {this.state.terminate}<br/>
                                </Card.Text>
                                </Card.Body>
                                </Card>
                            
                            
                            </Col>

                            <Col  xs={4} style={{textAlign:'center'}} >
                            
                            <Card
                              border="primary" 
                              text="primary" 
                             
                            >
                                <Card.Body> 
                                    <Alert  variant="primary" text="primary"  key='danger' ><b>Bill Generated by the Portal</b></Alert>
                                     <Card.Title>
                            
                                    Number of Bills generated this month: {this.state.billCountMonth}
                                </Card.Title>
    
                                <Card.Text>
                                    Total Number of bills generated till date: {this.state.billCountTotal}
                                    <br/>
                                    Total number of bills paid till date: {this.state.billCountPaid}<br/>
                                </Card.Text>
                                </Card.Body>
                                </Card>
                            
                                
                                </Col>
                           
                            {/* <Col>
                            </Col> */}
                    {/* </Row> */}
{/* -------------------------------------------------------------------------- */}
{/* <br/> <br/> */}
{/* <Row> */}
                        {/* <Col></Col> */}
                        <Col  xs={4} style={{textAlign:'center'}} >
                            
                        <Card
                              border="danger" 
                              text="danger" 
                             
                            >
                                <Card.Body> 
                                    <Alert  variant="danger" key='danger' > <b>Amount Collected </b></Alert>
                                     <Card.Title>
                            
                                     Amount Collected this month: {this.state.amountMonth}
                                </Card.Title>
    
                                <Card.Text>
                                Total Amount Collected by the portal: {this.state.amountTotal}
                                     <br/>
                                    Amount Collected as Advance payments: {this.state.amountAdvance}<br/>
                                </Card.Text>
                                </Card.Body>
                                </Card>
                            
                            
                            </Col>

                    </Row>


                
                </Container>
            </div>
        );
    }
}