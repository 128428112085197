import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import PrivateRoute from '../routes/PrivateRoute';
import PublicRoute from '../routes/PublicRoute';
import AdminPanel from './AdminPanel.js';
import JePanel from './JePanel.js';
import Login from './Login.js';
// import { Route, Redirect } from 'react-router-dom';


function FirstPage() {
    return (
      <BrowserRouter>
          <Switch>
          <PublicRoute restricted={true} component={Login} path="/" exact />
            <PublicRoute component={AdminPanel} path="/AdminPanel" exact />
            <PublicRoute component={JePanel} path="/JePanel" exact />
        
          </Switch>
        </BrowserRouter>
    );
  }
  
  export default FirstPage;
  