import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import UnpaidBill from './UnpaidBill';
import PaidBill from "./PaidBill";
import MyConsumers from "./MyConsumers";



export default class AdminReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            vColor: '',
            show: false,
            setShow: false,
            showAlert: false,
            allBillRecord: []

        }
    }

    setShow = () => {
        this.setState({
            showAlert: false
        })
    }





    render() {
        console.log(this.state.oldReading)
        return (
            <div>
                <Container fluid>
                    {/* <Row>
                        <Col style={{ textAlign: 'center' }}><h3><b><em><u>DOWNLOAD REPORTS</u></em></b></h3></Col>
                    </Row>
                    <br />
                    <br /> */}

                    <Alert show={this.state.showAlert} variant={this.state.vColor} onClose={this.setShow} dismissible>
                        {this.state.message}
                    </Alert>

                    <Row><Col>
                        {/*  */}
                        <Tab.Container defaultActiveKey="Unpaid Bills" transition={false} id="controlled-tab-example">
                            <Row><Col>

                                <Nav variant="tabs" defaultActiveKey="Saved">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Unpaid Bills">All Bills</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Paid Bills">Bill Payments </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="Advance Paid Bills">Advance Paid Bills</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Partly Paid Bills">Partly Paid Bills</Nav.Link>
                                    </Nav.Item> */}

                                    <Nav.Item>
                                        <Nav.Link eventKey="My Consumers">My Consumers</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col></Row>


                            <Row><Col>
                                <Tab.Content sm={12}>
                                    <Tab.Pane eventKey="Unpaid Bills">
                                        <UnpaidBill />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Paid Bills">
                                       <PaidBill />
                                    </Tab.Pane>
                              
                                    <Tab.Pane eventKey="My Consumers">
                                    <MyConsumers />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                    </Row>

                    <br />
                    <br />
                    <br />
                </Container>
            </div>
        )
    }
}