import Axios from "axios";

export default function HandleActivateConsumer(record_id){
    console.log('axios func');
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'activateConsumer',
            record_id           
        }
    });
    return response;
}