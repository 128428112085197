import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Card from 'react-bootstrap/Card';

import {
    
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import ViewMonthRecordDashboard from "../methods/ViewMonthRecordDashboard";
import ViewConsumerRecordStatus from '../methods/ViewConsumerRecordStatus';

export default class JeDashboard extends React.Component{
    constructor(props){
        super(props);

        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
        // const newDate = new Date(Date.now() + 15 * 24*60*60*1000);

            this.state={
            // terminate:0,
                pending:0,
                // approve:0
                totalBill:0,
                paidBill:0,
                pendingPayment:0,
                approvedBill:0,
                viewDataMonthYear:minDate,
                totalConsumer:0
            }
}
    componentDidMount=()=>{
        this.viewConsumerRecordStatus()
    }
    viewConsumerRecordStatus=()=>{
        ViewConsumerRecordStatus().then(resp=>{
            this.setState({
                terminate:resp.data.terminate,
                pending:resp.data.pending,
                approve:resp.data.approve,
                totalConsumer:resp.data.totalConsumer
            })
        })

    }
      
    setViewMonthRecordDashboard=(e)=>{
            this.setState({
                viewDataMonthYear:e
            })
            this.handleViewMonthRecordDashboard(e)
    }


    handleViewMonthRecordDashboard=(recordMonthYear)=>{
        ViewMonthRecordDashboard(recordMonthYear).then(resp=>{
       if(resp){
           if(resp.data==='NO'){
               this.setState({
                totalBill:0,
                paidBill:0,
                pendingPayment:0,
                // approvedBill:'No Record'
                
               })
           }
           else{
               this.setState({
                totalBill:resp.data.totalBillMonth,
                paidBill:resp.data.receiptNo,
                pendingPayment:resp.data.pending,
                // approvedBill:resp.data.approvedBill
               })
           }
       }
        })
    }
    render(){
        return(
            <div >
                <Container fluid >                  
                  
                    
                    <Row>
                    <Col xs={6}>
                        <Card                         
                        border="info" 
                        style={{bg:'linear-gradient(to right, red , yellow)'}}
                        >
                           <Card.Img variant="top"  />
                           <Card.Header style={{textAlign:'center'}} >
                               <b>MONTH WISE DETAIL</b>
                           </Card.Header>
                            <Card.Body>                               
                                <Card.Text>
                                    <Row>
                                        <Col>
                                                <label>
                                                    <b>Select Month-Year </b>
                                                </label> 
                                        </Col> 
                                        <Col>
                                            <MuiPickersUtilsProvider  
                                                utils={DateFnsUtils}>      
                                                    <DatePicker    
                                                        views={["month","year"]}                                                                    
                                                        value={this.state.viewDataMonthYear} 
                                                        onChange={this.setViewMonthRecordDashboard}                                                                     
                                                    />                    
                                            </MuiPickersUtilsProvider> 
                                        </Col>
                                    </Row>
                                    <hr />
                                   <Row >
                                      <Col>Total Bills</Col>
                                      <Col><b>{this.state.totalBill}</b></Col>
                                     
                                   </Row>
                                   <hr/>
                                   <Row>
                                      <Col>Payment Done</Col>
                                      <Col><b>{this.state.paidBill}</b></Col>
                                   </Row>
                                   <hr/>
                                   <Row>
                                      <Col>Payment Pending </Col>
                                      <Col><b>{this.state.pendingPayment}</b></Col>
                                   </Row>
                                   
                                   {/* <Row>
                                      <Col>Approved Bill </Col>
                                      <Col><b>{this.state.approvedBill}</b></Col>
                                   </Row> */}
                            
                                </Card.Text>                               
                            </Card.Body>
                        </Card>
                    </Col>
                        <Col  xs={6}  >                            
                        <Card
                         
                         border="info" 
                         style={{bg:'linear-gradient(to right, red , yellow)'}}               
                        >
                            <Card.Header style={{textAlign:'center'}}>
                                <b>CURRENT CONSUMER STATUS</b>
                            </Card.Header>
                            <Card.Body
                            background="linear-gradient(rgba(250,0,0,0.5),transparent)"
                            >
                            <Row>
                                <Col>Number of Pending Consumers:</Col>
                                <Col><b> {this.state.pending}</b></Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col>Number of Approved Consumers</Col>
                                <Col><b>  {this.state.approve}</b></Col>
                            </Row>
                            <hr/>
                            <Row>
                                <Col>Number of Terminated Consumers</Col>
                                <Col><b> {this.state.terminate}</b></Col>
                            </Row>
                            <hr/>  

                             <Row>
                                <Col>Total Number of Consumers</Col>
                                <Col><b> {this.state.totalConsumer}</b></Col>
                            </Row>
                            
                                             
                            </Card.Body>
                            </Card>
                            
                            </Col>
                            {/* <Col xs={2}></Col> */}
                            {/* <Col xs={4}>
                                  <Card 
                                 
                                  border="info" 
                                  >
                                <Card.Img variant="top"  />
                                <Card.Body>
                                    <Card.Title>Card Title</Card.Title>
                                    <Card.Text>
                                    Some quick example text to build on the card title and make up the bulk of
                                    the card's content.
                                    </Card.Text>
                                    <Button variant="primary">Go somewhere</Button>
                                </Card.Body>
                                </Card>
                            </Col> */}
                    </Row>
                   {/* <Row>
                        
                        // <Col  xs={4} style={{textAlign:'center'}} >
                            
                        // <Card
                        //  border="info" 
                         
                        // >
                        //     <Card.Header>CURRENT CONSUMER STATUS</Card.Header>
                        //     <Card.Body>
                        //     <Card.Title>
                        //         Number of Pending Consumers: {this.state.pending}
                        //     </Card.Title>

                           
                        //     </Card.Body>
                        //     </Card>
                            
                        //     </Col>
                        //     <Col xs={6}>
                        //           <Card 
                        //           //style={{ width: '18rem' }}
                        //           border="info" 
                        //           >
                        //         <Card.Img variant="top"  />
                        //         <Card.Body>
                        //             <Card.Title>Card Title</Card.Title>
                        //             <Card.Text>
                        //             Some quick example text to build on the card title and make up the bulk of
                        //             the card's content.
                        //             </Card.Text>
                        //             <Button variant="primary">Go somewhere</Button>
                        //         </Card.Body>
                        //         </Card>
                        //     </Col>
                    </Row> */}


                
                </Container>
            </div>
        );
    }
}