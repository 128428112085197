import Axios from "axios";
export default function DownloadPaymentReceipt(paymentId){ 
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL,{      
     responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }, 
        params: {
            a:'downloadPaymentReceipt',
            paymentId          
        }        
    });
    return response;
}