import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from "react-bootstrap/Button";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import MaterialTable from 'material-table';
import Jumbotron from 'react-bootstrap/Jumbotron';
import AddNewConsumer from '../methods/AddNewConsumer'; 
import ViewAllConsumer from '../methods/ViewAllConsumer';
import EditConsumer from '../methods/EditConsumer';
import MaxConsumerId from '../methods/MaxCounsumerId';

export default class JENewConsumer extends React.Component { 
    state={
      accountNo:'',
      name:'',
      emailId:'',
      address:'',
      load:'',
      ctCharge:'',
      meterType:'',
      meterRent:'',
      type:'',
      consumerType:'',
      consumerList:[],
      OldAccountNo:'',
      meterNo:'',
      serviceCharge:'',
      ccEmail:'',
      message:'',
      vColor:'',
      showAlert:false,
      depttNumber:'',
      depttAbbrevation:'',
      DbCounsumerId:'',
      consumerTypeAbbrevation:'',
    }
    
  componentDidMount=()=>{
      this.handleViewAllConsumer();
      this.handleMaxConsumerId();
  }
     
  
  handleMaxConsumerId=(event)=>{
    //event.preventDefault();
    MaxConsumerId().then(resp=>{
        if(resp){
            if(resp.data==='No'){
              this.setState({
                
                message:'No Record Found',
                vColor:'danger'
              })

            }
            else{
                this.setState({
                    DbCounsumerId:resp.data,
                    message:'',
                    vColor:''                      
                })
            }                

        }
        
    })
}



  setDepttNumber=(e)=>{
      this.setState({
          depttNumber:e.target.value,
          depttAbbrevation:'',
          accountNo:'',
          showAlert:false,          
      })
  }
  setDepttAbbrevation=(e)=>{
      const{consumerTypeAbbrevation,depttNumber,DbCounsumerId}=this.state
      var accountNumberVar=consumerTypeAbbrevation+e.target.value+depttNumber+DbCounsumerId;
      this.setState({
          depttAbbrevation:(e.target.value).toUpperCase()
      })
      console.log(accountNumberVar.length)
      if(accountNumberVar.length==11){
            this.setAccountNo(accountNumberVar);
            this.setState({
                message:'',
                vColor:''
            })
        }        
        else{
            this.setState({
                message:'Account Number Should be of 11 Digits',
                vColor:'danger',
                showAlert:true,
            })
        }
  }

  setAccountNo=(accountNoVar)=>{
    this.setState({
        accountNo:accountNoVar
    })
  }
    
    
    // setAccountNo=(e)=>{
    //   this.setState({
    //       accountNo:e.target.value
    //   })
    // }
     
    setName=(e)=>{
        this.setState({
            name:e.target.value
        })
    }

    setEmail=(e)=>{
        this.setState({
            emailId:e.target.value
        })
    }

    setAddress=(e)=>{
        this.setState({
            address:e.target.value
        })
    }

    setLoad=(e)=>{
        this.setState({
            load:e.target.value
        })
    }

    setCtCharge=(e)=>{
        this.setState({
            ctCharge:e.target.value
        })
    }

    setMeterType=({target:{value}})=>{
        this.setState({
            meterType:value
        })
    }

    setMeterRent=(e)=>{
        this.setState({
            meterRent:e.target.value
        })
    }

    setType=({target:{value}})=>{
        this.setState({
            type:value
        })
    }

    setConsumerType=({target:{value}})=>{
       
        var consumerTypeVar='';
        if(value=='GOV'){
             consumerTypeVar='Government';
        }
        else if(value=='PVT'){
             consumerTypeVar='Private';
        }
        else if(value=='PAU'){
             consumerTypeVar='PAU';
        }
        else if(value=='GDS'){
            consumerTypeVar='Gadvasu';
        }
        else if(value=='TEM'){
            consumerTypeVar='Temp';
        }
        else if(value=="ICR"){
            consumerTypeVar='ICAR';
        }

        this.setState({
            consumerType:consumerTypeVar,
            consumerTypeAbbrevation:value
        })
    }

    setOldAccountNo=(e)=>{
        this.setState({
            OldAccountNo:e.target.value
        })
    }

    setMeterNo=(e)=>{
        this.setState({
            meterNo:e.target.value            
        })
    }
    
    setServiceCharge=(e)=>{
         this.setState({
             serviceCharge:e.target.value
         })
    }
    setCcEmail=(e)=>{
        this.setState({
            ccEmail:e.target.value
        })
    }
    setShow=()=>{
        this.setState({
            showAlert:false
        })
    }
    handleAddNewConsumer=(event)=>{
        event.preventDefault();
        const{accountNo,name,emailId,address,load,ctCharge,meterType,meterRent,type,consumerType,OldAccountNo,meterNo,serviceCharge}=this.state;
       AddNewConsumer(accountNo,name,emailId,address,load,ctCharge,meterType,meterRent,type,consumerType,OldAccountNo,meterNo,serviceCharge).then(resp=>{
          if(resp){
              if(resp.data==='No'){
                 this.setState({
                     message:'Some Issue Raised. Try Again.',
                     showAlert:true,
                     vColor:'danger'
                 })
              }
              else{
                  this.setState({
                      message:'New Consumer Added Succesully.',
                      showAlert:true,
                      vColor:'success',
                      accountNo:'',
                      name:'',
                      emailId:'',
                      address:'',
                      load:'',
                      ctCharge:'',
                      meterType:'',
                      meterRent:'',
                      type:'',
                      consumerType:'',
                      OldAccountNo:'',
                      meterNo:'',
                      serviceCharge:''

                  })

              }
          }
       })
    }
    handleViewAllConsumer=(event)=>{
        //event.preventDefault();
        ViewAllConsumer().then(resp=>{
            if(resp){
                if(resp.data==='No'){
                  this.setState({
                    consumerList:[],
                    message:'No Record Found',
                    vColor:'danger'
                  })

                }
                else{
                    this.setState({
                        consumerList:resp.data,
                        message:'',
                        vColor:''                      
                    })
                }                

            }
            
        })
    }

    handleEditConsumer=(i,e)=>{
        const{accountNo,name,emailId,address,load,ctCharge,meterType,meterRent,type,consumerType}=this.state;
    EditConsumer(i,accountNo,name,emailId,address,load,ctCharge,meterType,meterRent,type,consumerType).then(resp=>{
        if(resp){
              
        }
    })
    }
    

    render(){
        return(
            <div>
                <Container fluid>
                {/* <Row>
                    <Col style={{textAlign:'center'}}><h3><b><em><u> ADD/ VIEW CONSUMER RECORD</u></em></b></h3></Col>
                </Row> */}
                
                <Tab.Container  defaultActiveKey="view" transition={false} id="controlled-tab-example">
                    <Row>
                        <Col>
                                <Nav variant="tabs" defaultActiveKey="view">
                                    <Nav.Item>
                                        <Nav.Link eventKey="view">View Consumers
                                        <b style={{color:'red',paddingLeft:'5px'}}>
                                            ({this.state.consumerList.length})
                                        </b>
                                        
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="newConsumer">New Consumer</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                        </Col>
                    </Row>                    

                    {this.state.message? 
                    <Row>
                        <Col style={{paddingTop:'5px'}}>
                                 <Alert  show={this.state.showAlert}  variant={this.state.vColor} dismissible onClose={this.setShow}  >
                                            {this.state.message}
                                        </Alert>
                        </Col>
                    </Row>
                         :''}

                    <Row>
                        <Col style={{paddingTop:'10px'}}>
                             <Tab.Content sm={12}>
                                 <Tab.Pane eventKey="view">
                                     <Form>
                                         <Row>
                                             <Col>                                               
                                                        <MaterialTable
                                                            name="Consumer List"
                                                            title="Consumer List"   
                                    
                                                            columns  ={[
                                                            {title:'S.No',field:'sno'}, 
                                                            {title:'Type',field:'type'}, 
                                                            {title:'Old Account',field:'oldAccount'},                                                  
                                                            {title:'Account No', field:'accountNo'},
                                                            {title:'DepartmentName',field:'name'},
                                                            {title:'EmailId',field:'email'},
                                                            // {title:'CCTo',field:'ccTo'},
                                                            {title:'Address',field:'address'},
                                                            {title:'Load(Kw)',field:'load'},
                                                            {title:'Meter No',field:'mNumber'},
                                                            {title:'Meter Type',field:'mType'},
                                                            {title:'Meter Rent',field:'mRent'},
                                                            {title:'CT Charges',field:'ctCharges'},
                                                            {title:'Service Charges',field:'sCharges'},
                                                            {title:'Consumer Type',field:'consumerType'},
                                                            {title:'Admin Status',field:'adminStatus'},
                                                           // {title:'Status',field:'status'},
                                                            //{title:'Edit',field:'edit'}                                                   
                                                        ]}
                                
                                                        data={                               
                                                            this.state.consumerList.map((consumerListData,id)=>(                                                                
                                                                {  
                                                                    
                                                                    'sno':id+1,
                                                                    'type':consumerListData.type.slice(3),  
                                                                    'oldAccount':consumerListData.oldAccountNo,                                                                            
                                                                    'accountNo':consumerListData.accountNo,
                                                                    'name':consumerListData.name,
                                                                    'email':consumerListData.emailId,
                                                                    // 'ccTo':consumerListData.ccEmail?consumerListData.ccEmail:'N/A',
                                                                    'address':consumerListData.address,
                                                                    'load':consumerListData.loadKw,
                                                                    'mNumber':consumerListData.meterNo,
                                                                    'mType':consumerListData.meterType,
                                                                    'mRent':consumerListData.meterRent,
                                                                    'ctCharges':consumerListData.ctCharges,
                                                                    'sCharges':consumerListData.serviceCharges,
                                                                    'consumerType':consumerListData.govPrivate?consumerListData.govPrivate:'N/A',
                                                                    'adminStatus':(consumerListData.approvalStatus==1)?'Approved':(consumerListData.approvalStatus==2)?'Terminated':'Pending',
                                                                    //'status':consumerListData.status,
                                                                    // 'edit':  consumerListData.approvalStatus==1?(                                                                     
                                                                    //               <Button  size="sm" type="submit"
                                                                    //                        variant='outline-info'
                                                                    //                        value={consumerListData.record_id} 
                                                                    //                        onClick={this.handleEditConsumer.bind(this,consumerListData.record_id)}
                                                                    //                        >                                                                                               
                                                                                       
                                                                    //                     Edit
                                                                    //               </Button> ):''                                                                                     
                                                                }  
                                                            ))
                                                        }                             

                                                        options={{
                                                        exportButton: true,
                                                        pageSize: 5,
                                                        pageSizeOptions: [5, 10, 20, 50, 100,this.state.consumerList.length],
                                                        toolbar: true,
                                                        paging: true
                                                        
                                                        }} 
                                                        />                                          
                                             </Col>
                                         </Row>
                                         <br></br>
                                         <Row>
                                             <Col></Col>
                                         </Row>
                                     </Form>

                                 </Tab.Pane>
                                 <Tab.Pane eventKey="newConsumer">
                                 <Jumbotron>
                                 <Row><Col><p style={{color:'red'}}>All (*) marked fields are mandatory</p></Col></Row>
                                 <Form onSubmit={this.handleAddNewConsumer}>
                                 <fieldset style={{border:'3px solid',padding:'5px',paddingTop:'2%'}}>                                   
                                       <legend>Account Number Generation</legend> 
                                       
                                  <Row>
                                    <Col xs="auto">                                        
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Type</InputGroup.Text>
                                                {/* <FormControl id="inlineFormInputGroup" placeholder="Username" /> */}
                                                <Form.Control as="select" 
                                                        onChange={this.setConsumerType}
                                                        value={this.state.consumerTypeAbbrevation}
                                                                              required>
                                                      <option></option>
                                                      <option value="GOV">Government</option>
                                                      <option value="PVT">Private</option>
                                                      <option value="PAU">PAU</option>
                                                      <option value="GDS">Gadvasu</option>
                                                      <option value="TEM">Temp</option>
                                                      <option value="ICR">ICAR</option>                                                      
                                                     </Form.Control>                                       
                                            </InputGroup>
                                    </Col>

                                    <Col xs="auto">                                        
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Deptt. No.</InputGroup.Text>
                                                {/* <FormControl id="inlineFormInputGroup" placeholder="Username" /> */}
                                                <Form.Control 
                                                        value={this.state.depttNumber}
                                                        onChange={this.setDepttNumber}
                                                        minLength={1}
                                                        maxLength={2}
                                                        required                                                          
                                                    />
                                           
                                            </InputGroup>
                                    </Col>

                                    <Col xs="auto">                                        
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Deptt. Abbr.</InputGroup.Text>
                                                {/* <FormControl id="inlineFormInputGroup" placeholder="Username" /> */}
                                                <Form.Control 
                                                        value={this.state.depttAbbrevation}
                                                        onChange={this.setDepttAbbrevation}
                                                        minLength={1}
                                                        maxLength={4}
                                                        required                                                          
                                                    />
                                            </InputGroup>
                                    </Col>

                                    <Col xs="auto">                                        
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Count</InputGroup.Text>
                                                {/* <FormControl id="inlineFormInputGroup" placeholder="Username" /> */}
                                                <Form.Control 
                                                        value={this.state.DbCounsumerId}
                                                        onChange={this.setDbCounsumerId}
                                                        minLength={3}
                                                        maxLength={3}
                                                        required 
                                                        readOnly                                                         
                                                    />
                                            </InputGroup>
                                    </Col>
                                 
                                 </Row>
                                 </fieldset>
                                 <hr></hr>
                                
                                                                      
                                 <Row>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                       Account No <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="9">
                                                    <Form.Control 
                                                       maxLength={11}    
                                                       minLength={11}                                                      
                                                       value={this.state.accountNo}
                                                       onChange={this.setAccountNo}
                                                       readOnly
                                                       required
                                                    />
                                                  </Col>
                                               </Form.Group>
                                         </Col>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                  OldAccount <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                 <Col sm="9">
                                                    <Form.Control 
                                                        value={this.state.OldAccountNo}
                                                        onChange={this.setOldAccountNo}
                                                        minLength={8}
                                                        maxLength={14}
                                                        required                                                          
                                                    />
                                                 </Col>
                                             </Form.Group>
                                         </Col>
                                     </Row>
                                     
                                     <Row>
                                         
                                         <Col> 
                                              <Form.Group as={Row}  Qa controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                  Name <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                 <Col sm="9">
                                                    <Form.Control                                                          
                                                          onChange={this.setName}
                                                          value={this.state.name}
                                                          required
                                                    />
                                                 </Col>
                                             </Form.Group>
                                         </Col>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                       Meter No <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="9">
                                                    <Form.Control                                                           
                                                          value={this.state.meterNo}
                                                          onChange={this.setMeterNo}
                                                          maxLength={8}
                                                          required
                                                    />
                                                  </Col>
                                               </Form.Group>
                                         </Col>
                                     </Row>
                                     <Row>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                       Email Id<b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="9">
                                                    <Form.Control                                                         
                                                          onChange={this.setEmail}
                                                          value={this.state.emailId}
                                                          type="email"
                                                          required
                                                    />
                                                  </Col>
                                               </Form.Group>
                                         </Col>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                  Address <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                 <Col sm="9">
                                                    <Form.Control                                                           
                                                          onChange={this.setAddress}
                                                          value={this.state.address}
                                                          required
                                                    />
                                                 </Col>
                                             </Form.Group>
                                         </Col>
                                     </Row>
                                     <Row>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                       Load (Kw)<b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="9">
                                                    <Form.Control                                                          
                                                          onChange={this.setLoad}
                                                          value={this.state.load}
                                                          required
                                                          type={'number'}
                                                          min={0}
                                                          step={'.01'}
                                                         
                                                    />
                                                  </Col>
                                               </Form.Group>
                                         </Col>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                  CT Charges<b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                 <Col sm="9">
                                                    <Form.Control                                                           
                                                          onChange={this.setCtCharge}
                                                          value={this.state.ctCharge}
                                                          required
                                                          type={'number'}
                                                          min={0}
                                                          step={'.01'}
                                                    />
                                                 </Col>
                                             </Form.Group>
                                         </Col>
                                     </Row>
                                     <Row>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                       Meter Type <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="9">
                                                   <Form.Control as="select" onChange={this.setMeterType}
                                                                             value={this.state.meterType} 
                                                                             required>
                                                      <option></option>
                                                      <option value="Self">Self</option>
                                                      <option value="Pau">PAU</option>                                                     
                                                     </Form.Control>
                                                  </Col>
                                               </Form.Group>
                                         </Col>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                  Meter Rent <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                 <Col sm="9">
                                                    <Form.Control                                                          
                                                          onChange={this.setMeterRent}
                                                          value={this.state.meterRent}
                                                          type={'number'}
                                                          required
                                                          step={'.01'}
                                                    />
                                                 </Col>
                                             </Form.Group>
                                         </Col>
                                     </Row>
                                     <Row>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                      Tariff Type <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="9">
                                                     <Form.Control as="select" onChange={this.setType}
                                                                                value={this.state.type} 
                                                                                required>  
                                                      <option></option>                                                   
                                                      <option value="sopNRS">NRS</option>
                                                      <option value="sopDS">DS</option>
                                                      <option value="sopTemp">Temp</option>
                                                     </Form.Control>
                                                  </Col>
                                               </Form.Group>
                                         </Col>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                  Consumer Type <b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                 <Col sm="9">
                                                 <Form.Control                                                          
                                                          onChange={this.setServiceCharge}
                                                          value={this.state.consumerType}
                                                          required
                                                          readOnly
                                                    />

                                                    {/* <Form.Control as="select" 
                                                                onChange={this.setConsumerType}
                                                                value={this.state.consumerType}
                                                                required> */}
                                                      {/* <option></option>
                                                      <option value="Government" >Government</option>
                                                      <option value="Private">Private</option>
                                                      <option value="PAU">PAU</option>
                                                      <option value="Gadvasu">Gadvasu</option>
                                                      <option value="Temp">Temp</option> */}
                                                      
                                                     {/* </Form.Control> */}
                                                 </Col>
                                             </Form.Group>
                                         </Col>
                                     </Row>
                                     <Row>
                                         <Col> 
                                              <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                       Service Charges<b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="9">
                                                    <Form.Control                                                          
                                                          onChange={this.setServiceCharge}
                                                          value={this.state.serviceCharge}
                                                          required
                                                          type={'number'}
                                                          min={0}
                                                          step={'.01'}
                                                    />
                                                  </Col>
                                               </Form.Group>
                                         </Col>
                                         <Col> 
                                              {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                                 <Form.Label column sm="3">
                                                       CC to (email)<b style={{color:'red'}}>*</b>
                                                 </Form.Label>
                                                  <Col sm="9">
                                                    <Form.Control                                                          
                                                          onChange={this.setCcEmail}
                                                          value={this.state.ccEmail}
                                                          required
                                                          type="email"
                                                         
                                                         
                                                    />
                                                  </Col>
                                               </Form.Group> */}
                                         </Col>
                                     </Row>
                                             
                                        <Button  size="sm" style={{marginLeft:'50%',width:'150px'}}  type="submit"
                                         variant='outline-info' >
                                            Submit
                                        </Button>                               
                                </Form>
                                </Jumbotron>
                                 </Tab.Pane>
                             </Tab.Content>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                </Tab.Container>
                </Container>
            </div>
        );
    }
}