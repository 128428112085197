import Axios from "axios";

export default function ViewAllPaidBillRecord(billMonth){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'viewAllPaidBillRecord',       
            billMonth
        }
    });
    return response;
}