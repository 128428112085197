import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import MaterialTable from 'material-table';
import ViewAllConsumer from '../methods/ViewAllConsumer';
import HandleTerminateConsumer from '../methods/HandleTerminateConsumer';
import HandleApproveConsumer from '../methods/HandleApproveConsumer';
import HandleActivateConsumer from '../methods/HandleActivateConsumer';
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";


export default class ApproveConsumer extends React.Component{


    state={
        accountNo:'',
        name:'',
        emailId:'',
        address:'',
        load:'',
        ctCharge:'',
        meterType:'',
        meterRent:'',
        type:'',
        consumerType:'',
        consumerList:[],
        OldAccountNo:'',
        meterNo:'',
        serviceCharge:'',
        message:'',
        vColor:'',
        showAlert:false
      }
      
    componentDidMount=()=>{
        this.handleViewAllConsumer();
    }

    handleViewAllConsumer=(event)=>{
        //event.preventDefault();
        ViewAllConsumer().then(resp=>{
            if(resp){
                if(resp.data==='No'){
                  this.setState({
                    consumerList:[],
                    message:'No Record Found',
                    vColor:'danger'
                  })

                }
                else{
                    this.setState({
                        consumerList:resp.data,
                        //message:'',
                        //vColor:'success'                     
                    })
                }                

            }
            
        })
    }

    handleActivateConsumer(record_id){

         HandleActivateConsumer(record_id).then(resp=>{
             if(resp){
                 console.log(resp.data)
 
                 this.setState({
                     message:resp.data,
                     showAlert:true,
                     vColor:'success'   
                 })              
                 
             }
         })
         this.handleViewAllConsumer();
        
     }


     handleApproveConsumer(record_id){

         HandleApproveConsumer(record_id).then(resp=>{
             if(resp){
                 console.log(resp.data)
 
                 this.setState({
                     message:resp.data,
                     showAlert:true,
                     vColor:'success'   
                 })              
                 
             }
         })
         this.handleViewAllConsumer();
        
     }
     
    handleTerminateConsumer(record_id){
       console.log('func main');
        HandleTerminateConsumer(record_id).then(resp=>{
            if(resp){
                console.log(resp.data)

                this.setState({
                    message:resp.data,
                    showAlert:true,
                    vColor:'success'   
                })              
                
            }
        })
        this.handleViewAllConsumer();
       
    }

    setShow=()=>{
        this.setState({
            showAlert:false
        })
    }



    render(){
        return(
            <div>
                <Container fluid>
                {/* <Row>
                        <Col style={{textAlign:'center'}}>
                            <h3><b> Consumer Details for Approval/Termination/Activation</b></h3>
                        </Col>
                </Row>
                <br/>
                <br/> */}
                <Alert show={this.state.showAlert} variant={this.state.vColor} onClose={this.setShow} dismissible>
                         {this.state.message}
                </Alert>
             
                <Row>
                                             <Col>                                               
                                                        <MaterialTable

                                                            name="Consumer List"
                                                            title="Consumer Details for Approval/Termination/Activation"
                                                                                                
                                                            columns  ={[
                                                            {title:'S.No',field:'sno'}, 
                                                            {title:'Type',field:'type'}, 
                                                            {title:'Old Account',field:'oldAccount'},                                                  
                                                            {title:'Account No', field:'accountNo'},
                                                            {title:'Department Name',field:'name'},
                                                            {title:'EmailId',field:'email'},
                                                            {title:'Address',field:'address'},
                                                            {title:'Load(Kw)',field:'load'},
                                                            {title:'Meter No',field:'mNumber'},
                                                            {title:'Meter Type',field:'mType'},
                                                            {title:'Meter Rent',field:'mRent'},
                                                            {title:'CT Ratio',field:'ctCharges'},
                                                            {title:'Service Charges',field:'sCharges'},
                                                            // {title:'Consumer Type',field:'consumerType'},
                                                            {title:'Approval Status',field:'adminStatus'},
                                                            {title:'Action',field:'action'},
                                                           // {title:'Status',field:'status'},
                                                            //{title:'Edit',field:'edit'}                                                   
                                                        ]}
                                
                                                        data={                               
                                                            this.state.consumerList.map((consumerListData,id)=>(                                                                
                                                                {  
                                                                    
                                                                    'sno':id+1,
                                                                    'type':consumerListData.type.slice(3),  
                                                                    'oldAccount':consumerListData.oldAccountNo,                                                                            
                                                                    'accountNo':consumerListData.accountNo,
                                                                    'name':consumerListData.name,
                                                                    'email':consumerListData.emailId,
                                                                    'address':consumerListData.address,
                                                                    'load':consumerListData.loadKw,
                                                                    'mNumber':consumerListData.meterNo,
                                                                    'mType':consumerListData.meterType,
                                                                    'mRent':consumerListData.meterRent,
                                                                    'ctCharges':consumerListData.ctCharges,
                                                                    'sCharges':consumerListData.serviceCharges,
                                                                    // 'consumerType':consumerListData.govPrivate,
                                                                    'adminStatus':(consumerListData.approvalStatus===1)?'Approved':(consumerListData.approvalStatus===2)?'Terminated':'Pending',
                                                                    'action':consumerListData.approvalStatus?(consumerListData.approvalStatus===1?(<Button size="sm"  variant='outline-danger'   onClick={this.handleTerminateConsumer.bind(this,consumerListData.record_id)}>Terminate</Button>):(<Button  size="sm"  variant='outline-info' onClick={this.handleActivateConsumer.bind(this,consumerListData.record_id)}>Activate</Button>)):<Button  size="sm"  variant='outline-success' onClick={this.handleApproveConsumer.bind(this,consumerListData.record_id)}>Approve</Button>,
                                                                    
                                                                                                                                                       
                                                                }  
                                                            ))
                                                        }                             

                                                        options={{
                                                        exportButton: true,
                                                        pageSize: 5,
                                                        pageSizeOptions: [5, 10, 20, 50,this.state.consumerList.length],
                                                        toolbar: true,
                                                        paging: true
                                                        
                                                        }} 
                                                        />  
                                                    
                                                 
                                             </Col>
                                         </Row>
                                         <br/>
                                         <br/>

                                         <br/>
                                         <br/>

                </Container>
            </div>
        );
    }
}

