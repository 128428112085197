import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import MaterialTable from 'material-table';
import ViewAllConsumer from '../methods/ViewAllConsumer';




export default class PaidBill extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            vColor: '',
            show: false,
            setShow: false,
            showAlert: false,
            myConsumers: []

        }
    }
    componentDidMount = () => {
        this.handleViewAllConsumer();
    }

    handleViewAllConsumer = (event) => {
        //event.preventDefault();
        ViewAllConsumer().then(resp => {
            if (resp) {
                if (resp.data === 'No') {
                    this.setState({
                        myConsumers: [],
                        message: 'No Record Found',
                        vColor: 'danger'
                    })

                }
                else {
                    this.setState({
                        myConsumers: resp.data,
                        //message:'',
                        //vColor:'success'                     
                    })
                }

            }

        })
    }




    setShow = () => {
        this.setState({
            showAlert: false
        })
    }





    render() {
        console.log(this.state.oldReading)
        return (
            <div>
                <Container fluid>

                    <br />
                    <br />

                    <Alert show={this.state.showAlert} variant={this.state.vColor} onClose={this.setShow} dismissible>
                        {this.state.message}
                    </Alert>



                    <Row>
                        <Col>
                            <MaterialTable

                                name="Consumer List"
                                title="List of All Consumers"

                                columns={[
                                    { title: 'S.No', field: 'sno' },
                                    { title: 'Type', field: 'type' },
                                    { title: 'Old Account', field: 'oldAccount' },
                                    { title: 'Account No', field: 'accountNo' },
                                    { title: 'Department Name', field: 'name' },
                                    { title: 'EmailId', field: 'email' },
                                    { title: 'Address', field: 'address' },
                                    { title: 'Load(Kw)', field: 'load' },
                                    { title: 'Meter No', field: 'mNumber' },
                                    { title: 'Meter Type', field: 'mType' },
                                    { title: 'Meter Rent', field: 'mRent' },
                                    { title: 'CT Ratio', field: 'ctCharges' },
                                    { title: 'Service Charges', field: 'sCharges' },
                                    { title: 'Consumer Type', field: 'consumerType' },
                                    { title: 'Approval Status', field: 'adminStatus' },

                                ]}

                                data={
                                    this.state.myConsumers.map((consumerListData, id) => (
                                        {

                                            'sno': id + 1,
                                            'type': consumerListData.type,
                                            'oldAccount': consumerListData.oldAccountNo,
                                            'accountNo': consumerListData.accountNo,
                                            'name': consumerListData.name,
                                            'email': consumerListData.emailId,
                                            'address': consumerListData.address,
                                            'load': consumerListData.loadKw,
                                            'mNumber': consumerListData.meterNo,
                                            'mType': consumerListData.meterType,
                                            'mRent': consumerListData.meterRent,
                                            'ctCharges': consumerListData.ctCharges,
                                            'sCharges': consumerListData.serviceCharges,
                                            'consumerType': consumerListData.govPrivate,
                                            'adminStatus': (consumerListData.approvalStatus === 1) ? 'Approved' : (consumerListData.approvalStatus === 2) ? 'Terminated' : 'Pending',


                                        }
                                    ))
                                }

                                options={{
                                    exportButton: true,
                                    pageSize: 5,
                                    pageSizeOptions: [5, 10, 20, 50, this.state.myConsumers.length],
                                    toolbar: true,
                                    paging: true

                                }}
                            />


                        </Col>

                    </Row>
                    <br />
                    <br />
                    <br />
                </Container>
            </div>
        )
    }
}