import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Alert from 'react-bootstrap/Alert';
import MaterialTable from 'material-table';
import ViewAllUnpaidBillRecord from '../methods/ViewAllUnpaidBillRecord';




export default class UnpaidBill extends React.Component {
     constructor(props){
         super(props);    
        this.state={        
        message:'',       
        vColor:'',
        show:false,
        setShow:false,
        showAlert:false,
        allBillRecord:[]
        
    }
}
    componentDidMount=()=>{
            this.handleViewAllUnpaidBillRecord();
    }

   

    handleViewAllUnpaidBillRecord=()=>{
        ViewAllUnpaidBillRecord().then(resp=>{
            if(resp){
                if(resp.data==='No'){
                    this.setState({
                        message:'No Record Found',
                        vColor:'danger'
                    })
                }
                else{
                    this.setState({
                        // message:'',
                        // vColor:'',
                       allBillRecord:resp.data
                    })
                }
            }
            else{

            }
        })
    }



    
   
     setShow=()=>{
         this.setState({
             showAlert:false
         })
     }
 
 



    render(){        
        console.log(this.state.oldReading)
        return(
            <div>
                <Container fluid>
               
                <br/>
                <br/> 

                <Alert show={this.state.showAlert} variant={this.state.vColor} onClose={this.setShow} dismissible>
                         {this.state.message}
                </Alert>

               
                                
                <Row>
                    <Col>
                        <MaterialTable
                                                            name="List of All Bills"
                                                                title="List of  All Bills"   
                                        
                                                                columns  ={[
                                                                {title:'S.No',field:'sno'}, 
                                                                {title:'BillMonth ', field:'billMonth'},
                                                                {title:'Bill No ', field:'billNo'},
                                                            {title:'Acccount No.',field:'accNo'},
                                                            {title:'CustomerName',field:'name'},                                                          
                                                            {title:'IssueDate',field:'issueDate'},
                                                            {title:'DueDate',field:'dueDate'},
                                                            {title:'AmountPayable',field:'amountPayable'},
                                                                                                            
                                                        ]}
                                                data={
                                                this.state.allBillRecord.map((allBill,id)=>(
                                                    {
                                                        'sno':id+1,
                                                        'billMonth':allBill.billMonth,
                                                        'billNo':allBill.billNo,
                                                        'accNo':allBill.accountNo,
                                                        'name':allBill.name,
                                                        'issueDate':allBill.issueDate,
                                                        'dueDate':allBill.dueDate,
                                                        'amountPayable':allBill.amountPayable
                                
                                                    }
                                                ))
                                            }
                                            options={{
                                                exportButton: true,
                                                pageSize: 10,
                                                pageSizeOptions: [10, 20, 50, 100,this.state.allBillRecord.length],
                                                toolbar: true,
                                                paging: true
                                                
                                                }} 
                                                   />
                                            </Col>
                                            
                                        </Row>
                                        <br/>
                                            <br/>
                                            <br/>
                              </Container>
            </div>
        )
    }
 }