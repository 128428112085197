import Axios from "axios";

export default function ViewApprovalPendingRecordAdmin(){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'viewApprovalPendingRecordAdmin',  
            
        }
    });
    return response;
}