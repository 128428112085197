import Axios from "axios";

export default function SubmitCharges(year,sopNRS,sopDS,sopTEMP,municipal,ed,infrCess,dueDays,hoursPerDay,demandFactor,misc1,miscRemark){
    console.log(year,sopNRS,sopDS,sopTEMP,municipal,ed,infrCess,dueDays,hoursPerDay,demandFactor,misc1,miscRemark);
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'submitCharges',
            year,
            sopNRS,
            sopDS,
            sopTEMP,
            municipal,
            ed,
            infrCess,
            dueDays,
            hoursPerDay,
            demandFactor,
            misc1,
            miscRemark
        }
    });
    return response;
}