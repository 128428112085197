import axios from "axios";

export  default function LoginAuthentication(username, password){   
  let response=axios.get(process.env.REACT_APP_GATEWAY_URL,
     {
        params:{
        a: 'loginAuthentication', 
         username,
         password      
     }
   }
  );
  return response;
};
