import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import MaterialTable from 'material-table';
import Button from "react-bootstrap/Button";
// import AdminViewAllBillRecord from '../methods/AdminViewAllBillRecord';
import DownloadBill from '../methods/DownloadBill';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Alert from "react-bootstrap/Alert";
import ViewMonthlyBillRecord from '../methods/ViewMonthlyBillRecord';

import {
    
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";


export default class DownloadApproveBill extends React.Component{
    constructor(props){
        super(props);
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
        const newDate = new Date(Date.now() + 15 * 24*60*60*1000);

       this.state={        
       message:'',       
       vColor:'',
       show:false,
       setShow:false,
       showAlert:false,
       allBillRecord:[],
       billMonth:minDate,
       //vColor:''
      // showAlert:false
       
   }
}

    componentDidMount=()=>{
           
     //this.handleAdminViewAllBillRecord(this.state.billMonth);
     this.handleViewMonthlyBillRecord(this.state.billMonth);
      
}

setBillMonth=(e)=>{
    this.setState({
        billMonth:e
    })
    //this.handleAdminViewAllBillRecord(e);
       this.handleViewMonthlyBillRecord(e);
}


//handleAdminViewAllBillRecord=(e)=>{
    handleViewMonthlyBillRecord=(e)=>{
    //console.log(e);
   //AdminViewAllBillRecord(e).then(resp=>{
    ViewMonthlyBillRecord(e).then(resp=>{
       if(resp){
           if(resp.data==='No'){
               this.setState({
                   message:'No Record Found',
                   vColor:'danger',
                   showAlert:true,
                   allBillRecord:[]
               })
           }
           else{
               this.setState({
                   // message:'',
                   // vColor:'',
                   showAlert:false,
                  allBillRecord:resp.data
               })
           }
       }
       else{

       }
   })
}
setShow=()=>{
    this.setState({
        showAlert:false
    })
}

downloadBill=(billrecord_id)=>{
   // console.log('download me');
    console.log(billrecord_id)
    console.log(this.state.billMonth)

    DownloadBill(
        billrecord_id,
        this.state.billMonth
      ).then(resp=>{
    
    
    const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'BillReciept.pdf');
      document.body.appendChild(link);
      link.click();
      }).catch(error=>{
        
      });
    

}



    render(){
        return(
            <div>
                  <Container fluid>
                {/* <Row>
                        <Col style={{textAlign:'center'}}>
                            <h3><b>DOWNLOAD APPROVED BILL</b></h3>
                        </Col>
                </Row> */}
                <Alert show={this.state.showAlert} variant={this.state.vColor} onClose={this.setShow} dismissible>
                         {this.state.message}
                </Alert>
                {/* {this.state.message?
                <Row>
                    <Col>
                   
                    {this.state.message}
                    
                    </Col>
                </Row>
                :''} */}
            

                <Row>

                                                        <Col>   
                                                        <Jumbotron>         
                                                            <h3>Download the bill generated in the selected Month of a Year.</h3>                                             
                                                            <MuiPickersUtilsProvider  utils={DateFnsUtils}>      
                                                                <DatePicker
                                                                        label='Select the Month'
                                                                        views={["month","year"]}
                                                                        value={this.state.billMonth}
                                                                        onChange={this.setBillMonth}                                                                        
                                                                                                                                     
                                                                    />                    
                                                            </MuiPickersUtilsProvider>
                                                            
                                                            </Jumbotron>
                                                            </Col>

                                                
                </Row>
                <Row>
                    <Col>
                        <MaterialTable
                                                            name="Download Approved Bills"
                                                                title="Download Approved Bills"   
                                        
                                                                columns  ={[
                                                                {title:'S.No',field:'sno'}, 
                                                                {title:'BillMonth ', field:'billMonth'},
                                                                {title:'Bill No ', field:'billNo'},
                                                                {title:'Acccount No.',field:'accNo'},
                                                                {title:'CustomerName',field:'name'},
                                                                {title:'Download',field:'action'}
                                                                ]}
                                                            
                                                            
                                                            data={
                                                                this.state.allBillRecord.map((allBill,id)=>(
                                                                    {
                                                                        'sno':id+1,
                                                                        'billMonth':allBill.billMonth,
                                                                        'billNo':allBill.billNo,
                                                                        'accNo':allBill.accountNo,
                                                                        'name':allBill.name,
                                                                        'action':allBill.billStatus?(<Button size="sm"  variant='outline-info'   onClick={this.downloadBill.bind(this,allBill.billrecord_id)}> Download Bill</Button>):'Bill Not Approved by Admin'
                                                       
                                                                    }
                                                                ))}

                                                                options={{
                                                                    exportButton: true,
                                                                    pageSize: 5,
                                                                    pageSizeOptions: [5, 10, 20, 50, 100,this.state.allBillRecord.length],
                                                                    toolbar: true,
                                                                    paging: true
                                                                    
                                                                    }} 
                                                                />
                                                                </Col>
                                                                </Row>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                                
                                                             
               
                   
                </Container>
            </div>
        );
    }
}


