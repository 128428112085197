import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import MaterialTable from 'material-table';
import ViewAdminLogs from '../methods/ViewAdminLogs';


export default class AdminLogs extends React.Component{
    state={
        adminLogs:[]
    }

    componentDidMount=()=>{
        this.handleViewAdminLogs();
    }

    handleViewAdminLogs=(event)=>{
        //event.preventDefault();
        ViewAdminLogs().then(resp=>{
            console.log(resp.data)
            if(resp){
                if(resp.data==='No'){
                  this.setState({
                    adminLogs:[],
                   
                  })

                }
                else{
                    this.setState({
                        adminLogs:resp.data,
                                   
                    })
                }                

            }
            
        })
    }

    render(){
        return(
            <div>
                <Container fluid>
                <Row>
                        <Col style={{textAlign:'center'}}>
                            <h3><b>TIMESTAMP</b></h3>
                        </Col>
                </Row>
                <br/>
                <br/>

                <Row>
                                             <Col>                                               
                                                        <MaterialTable

                                                            name="Logs"
                                                            title="Important Logs/Timestamps"
                                                                                                
                                                            columns  ={[
                                                            {title:'S.No',field:'sno'},
                                                            {title:'Acccount No.',field:'accNo'}, 
                                                            {title:'Consumer Form Submission by JE',field:'sub'}, 
                                                            {title:'Consumer Approval/Activation by Admin',field:'act'}, 
                                                            {title:'Consumer Termination by Admin',field:'ter'},  
                                                            ]}

                                                             data={                               
                                                                this.state.adminLogs.map((adminLogs,id)=>(                                                                
                                                                    {  
                                                                        
                                                                        'sno':id+1,
                                                                        'accNo':adminLogs.accountNo,
                                                                        'sub':adminLogs.log?adminLogs.log:'--',
                                                                        'act':adminLogs.formActiveApproveLog?adminLogs.formActiveApproveLog:'--',
                                                                        'ter': adminLogs.formTerminationLog? adminLogs.formTerminationLog:'--'
                                                                    }
                                                                )
                                                                )}

                                                                options={{
                                                                    exportButton: true,
                                                                    pageSize: 5,
                                                                    pageSizeOptions: [5, 10, 20, 50, 100,this.state.adminLogs.length],
                                                                    toolbar: true,
                                                                    paging: true
                                                                    
                                                                    }} 


                                                            />
                                                            </Col>
                                                            </Row>
                                                            <br/>
                                                            <br/>
                                                            <br/>
                    <br/>
                    <br/>
                </Container>
            </div>
        );
    }
}