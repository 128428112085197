import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from "react-bootstrap/Button";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import SubmitCharges from '../methods/SubmitCharges';
import SavedCharges from '../methods/SavedCharges';
import SaveLoad from "../methods/SaveLoad";
import LoadCharges from "../methods/LoadCharges";


export default class GlobalCharges extends React.Component { 
    state={
        showAlert:false,
        readonlyLoad:true,
        alertMessage:'',
        year:2022,
        sop:'',
        municipal:'',
        ed:'',
        infrCess:'',
        misc1:'',
        miscRemark:'',
        dueDays:'',
        hoursPerDay:'',
        demandFactor:'',
        vColor:'',
        loadUpto7: 0,
        loadUpto20: 0,
        loadUpto100: 0,
        loadAbove: 0,
        readOnlyLoad: true,
        disableEditload: false,
        disableSaveLoad: true,
        loadYear:'',
    }


    componentDidMount=()=>{
        this.getSavedCharges();
        this.getLoadCharges();
    }
    

    getLoadCharges=()=>{
        console.log("getLoadCharges")
        LoadCharges().then(resp=>{
            if(resp){
                if(resp.data=='NO'){
                    this.setState({

                    })
                }
                else{             

                    this.setState({
                        loadYear:resp.data[0].tarrifYear
                    })   
                    
                    if(resp.data[0].loadKwUpto===7)
                    {
                       console.log(resp.data[0].loadKwUpto)
                        this.setState({
                            loadUpto7:resp.data[0].rate                        
                        })

                    }
                    if(resp.data[1].loadKwUpto===20)
                    {
                        this.setState({
                            loadUpto20:resp.data[1].rate
                        
                        })

                    }
                    if(resp.data[2].loadKwUpto===100)
                    {
                        this.setState({
                        loadUpto100:resp.data[2].rate
                        })
                    }
                    if(resp.data[3].loadKwUpto===180)
                    {
                        this.setState({
                        loadAbove:resp.data[3].rate
                        })

                    }                    
                }
            }
        })
    }
    
    getSavedCharges=()=>{
        SavedCharges().then(resp=>{
            // console.log(resp.data)
            this.setState({
                sopNRS:resp.data.sopNRS,
                sopDS:resp.data.sopDS,
                sopTEMP:resp.data.sopTemp,
                municipal:resp.data.muncipal,
                ed:resp.data.ed,
                infrCess:resp.data.infrCess,
                dueDays: resp.data.dueDays,
                hoursPerDay:resp.data.hoursPerDay,
                demandFactor:resp.data.hoursPerDay,
                misc1:resp.data.misc1,
                miscRemark:resp.data.misc2,
            })
        })
        
    }

    setYear=(e)=>{
     this.setState({
         year:e.target.value
     })
    }

    setSopNRS=(e)=>{
        this.setState({
            sopNRS:e.target.value
        })
    }

    setSopDS=(e)=>{
        this.setState({
            sopDS:e.target.value
        })
    }

    setSopTemp=(e)=>{
        this.setState({
            sopTEMP:e.target.value
        })
    }

    setMunicipal=(e)=>{
        this.setState({
            municipal:e.target.value
        })
    }

    setEd=(e)=>{
        this.setState({
            ed:e.target.value
        })
    }

    setInfCess=(e)=>{
        this.setState({
            infrCess:e.target.value
        })
    }

    setMisc1=(e)=>{
        this.setState({
            misc1:e.target.value
        })
    }

    setmiscRemark=(e)=>{
        this.setState({
            miscRemark:e.target.value
        })
    }

    setdueDays=(e)=>{
        this.setState({
            dueDays:e.target.value
        })
    }

    sethoursPerDay=(e)=>{
        this.setState({
            hoursPerDay:e.target.value
        })
    }

    setdemandFactor=(e)=>{
        this.setState({
            demandFactor:e.target.value
        })
    }

    setLoadYear=(e)=>{
        this.setState({
            loadYear:e.target.value
        })
    }

    setLoad1 = (e) => {
        this.setState({
            loadUpto7: e.target.value
        })
    }
    setLoad2 = (e) => {
        this.setState({
            loadUpto20: e.target.value
        })
    }
    setLoad3 = (e) => {
        this.setState({
            loadUpto100: e.target.value
        })
    }

    setLoad4 = (e) => {
        this.setState({
            loadAbove: e.target.value
        })
    }

    setShow=()=>{
        this.setState({
            showAlert:false
        })
    }



    handleSubmitCharges=(event)=>{
        event.preventDefault();
        const{ year,sopNRS,sopDS,sopTEMP,municipal,ed,infrCess,dueDays,hoursPerDay,demandFactor,misc1,miscRemark}=this.state;
        SubmitCharges(year,sopNRS,sopDS,sopTEMP,municipal,ed,infrCess,dueDays,hoursPerDay,demandFactor,misc1,miscRemark).then(resp=>{
            if(resp){
                if(resp.data.alertMessage){
                    this.setState({
                       
                        showAlert:true,
                        vColor:'info',
                        alertMessage:resp.data.alertMessage,
                    })
                }
                else{
                    this.setState({
                        
                        //showAlert:

                    })
                }
            }
        })
    }

    handleEditLoad = () => {
        this.setState({
            readOnlyLoad: false,
            disableSaveLoad: false
        })
    }
    handleSaveLoad = () => {
        // alert('hello Save Load!')
        const{loadYear,loadUpto7,loadUpto20,loadUpto100,loadAbove}=this.state

        SaveLoad(loadYear,loadUpto7,loadUpto20,loadUpto100,loadAbove).then(resp=>{
            if(resp){
                if(resp.data){
                    this.setState({    
                    })
                }
                else{
                    this.setState({ })
                }
            }
        })
    }
    render(){
        
        return(
                        <div>
                <Container fluid>
                     {/* <Row>
                        <Col style={{textAlign:'center'}}><h3><b><em><u> ADD/ View Global Charges</u></em></b></h3></Col>
                    </Row> */}
                    {/* noamin-tab-example */}
                    <Tab.Container  defaultActiveKey="Saved" transition={false} id="controlled-tab-example">
                    <Row><Col>
                    
                    <Nav variant="tabs" defaultActiveKey="Saved">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Saved">Saved Information</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Edit">Edit Information</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                    <Nav.Link eventKey="load">Load Information</Nav.Link>
                                </Nav.Item>
                                    </Nav>
                    </Col></Row>


                    <Row><Col>
                    <Tab.Content sm={12}>
                    <Tab.Pane eventKey="load">
                                    <br/>
                                    <span style={{ color: 'red', fontSize: '0.2em' }}><h6>Note:Only Current/Latest Calender Year charges pattern is available on screen</h6></span>
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="3">
                                           Year
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control

                                                readOnly={this.state.readOnlyLoad}
                                                onChange={this.setLoadYear}
                                                value={this.state.loadYear}
                                                min={4}
                                                max={4}
                                            />
                                        </Col>
                                    </Form.Group>
                                   
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="3">
                                            Load upto 7kW
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control

                                                readOnly={this.state.readOnlyLoad}
                                                onChange={this.setLoad1}
                                                value={this.state.loadUpto7}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="sopNRS">
                                        <Form.Label column sm="3">
                                        Load Above 7kw and upto 20 kW
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                readOnly={this.state.readOnlyLoad}
                                                onChange={this.setLoad2}
                                                value={this.state.loadUpto20} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="sopDS">
                                        <Form.Label column sm="3">
                                            Load Above 20 kW and upto 100 kVA
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                readOnly={this.state.readOnlyLoad}
                                                onChange={this.setLoad3}
                                                value={this.state.loadUpto100} />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group as={Row} controlId="sopDS">
                                        <Form.Label column sm="3">
                                            Above 100 kVA
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control
                                                readOnly={this.state.readOnlyLoad}
                                                onChange={this.setLoad4}
                                                value={this.state.loadAbove} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group>
                                        <Row>

                                            <Col xs={2}>
                                                <Button variant='outline-primary' disabled={this.state.disableEditload} onClick={this.handleEditLoad} block> Edit</Button>
                                            </Col>
                                            <Col xs={2}>
                                                <Button variant='outline-info' disabled={this.state.disableSaveLoad} onClick={this.handleSaveLoad} block> Save</Button>
                                            </Col>
                                        
                                        </Row>
                                    </Form.Group>

                                </Tab.Pane>



                                    <Tab.Pane eventKey="Saved">
                                          <br/>

                                          <span style={{color:'red', fontSize:'0.2em'}}><h6>Note:Only Current/Latest Calender Year charges pattern is available on screen</h6></span>
                                            <Form onSubmit={this.handleSubmitCharges}>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="3">
                                            Year
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          plaintext 
                                                          readOnly
                                                          value={this.state.year}
                                                          />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="sopNRS">
                                            <Form.Label column sm="3">
                                            SOP NRS
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control                                                          
                                                         plaintext 
                                                         readOnly
                                                         value={this.state.sopNRS} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="sopDS">
                                            <Form.Label column sm="3">
                                            SOP DS
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control                                                          
                                                         plaintext 
                                                         readOnly
                                                         value={this.state.sopDS} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="sopTEMP">
                                            <Form.Label column sm="3">
                                            SOP TEMP
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control                                                          
                                                         plaintext 
                                                         readOnly
                                                         value={this.state.sopTEMP} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="municipalTax">
                                            <Form.Label column sm="3">
                                            Municipal Tax
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                         plaintext 
                                                         readOnly
                                                         type="text"
                                                         placeholder="00.00"
                                                         onChange={this.setMunicipal} 
                                                         value={this.state.municipal}/>
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="edTax">
                                            <Form.Label column sm="3">
                                            ED
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control
                                                          plaintext 
                                                          readOnly
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.setEd}
                                                          value={this.state.ed} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="infrcessTax">
                                            <Form.Label column sm="3">
                                            InfrCess
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          plaintext 
                                                          readOnly
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.setInfCess}
                                                          value={this.state.infrCess} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="dueDays">
                                            <Form.Label column sm="3">
                                            Due Days
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          plaintext 
                                                          readOnly
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.setdueDays}
                                                          value={this.state.dueDays} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="hoursPerDay">
                                            <Form.Label column sm="3">
                                            Hours Per Day
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          plaintext 
                                                          readOnly
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.sethoursPerDay}
                                                          value={this.state.hoursPerDay} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="demandFactor">
                                            <Form.Label column sm="3">
                                            Demand Factor
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          plaintext 
                                                          readOnly
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.setdemandFactor}
                                                          value={this.state.demandFactor} />
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="miscTax">
                                            <Form.Label column sm="3">
                                            Miscelleanous Charges 1
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control
                                                      plaintext 
                                                      readOnly
                                                      type="text" 
                                                      placeholder="00.00"
                                                      onChange={this.setMisc1}
                                                      value={this.state.misc1} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="miscRemarkTax">
                                            <Form.Label column sm="3">
                                            Miscelleanous Remark
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                         plaintext 
                                                         readOnly
                                                         type="text"
                                                         placeholder="00.00" 
                                                         onChange={this.setmiscRemark}
                                                         value={this.state.miscRemark}/>
                                            </Col>
                                        </Form.Group>

                                        {/* <Alert show={this.state.showAlert} variant="danger">
                                                {this.state.alertMessage}
                                            
                                        </Alert> */}
                                </Form>
                                    </Tab.Pane>
                                    {/* Edit------------------------------------------ */}
                                    <Tab.Pane eventKey="Edit">
                                          <br/>
                                            <Form onSubmit={this.handleSubmitCharges}>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="3">
                                            Year
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          //plaintext 
                                                          //readOnly
                                                          //defaultValue="2020"
                                                          onChange={this.setYear}
                                                          value={this.state.year}
                                                          />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="sopNRS">
                                            <Form.Label column sm="3">
                                            SOP NRS
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                         type="text" 
                                                         placeholder="00.00"
                                                         onChange={this.setSopNRS}
                                                         value={this.state.sopNRS} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="sopDS">
                                            <Form.Label column sm="3">
                                            SOP DS
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                         type="text" 
                                                         placeholder="00.00"
                                                         onChange={this.setSopDS}
                                                         value={this.state.sopDS} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="sopTEMP">
                                            <Form.Label column sm="3">
                                            SOP TEMP
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                         type="text" 
                                                         placeholder="00.00"
                                                         onChange={this.setSopTemp}
                                                         value={this.state.sopTEMP} />
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="municipalTax">
                                            <Form.Label column sm="3">
                                            Municipal Tax
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                         type="text"
                                                         placeholder="00.00"
                                                         onChange={this.setMunicipal} 
                                                         value={this.state.municipal}/>
                                            </Col>
                                        </Form.Group>


                                        <Form.Group as={Row} controlId="edTax">
                                            <Form.Label column sm="3">
                                            ED
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.setEd}
                                                          value={this.state.ed} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="infrcessTax">
                                            <Form.Label column sm="3">
                                            InfrCess
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.setInfCess}
                                                          value={this.state.infrCess} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="dueDays">
                                            <Form.Label column sm="3">
                                            Due Days
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.setdueDays}
                                                          value={this.state.dueDays} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="hoursPerDay">
                                            <Form.Label column sm="3">
                                            Hours Per Day
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.sethoursPerDay}
                                                          value={this.state.hoursPerDay} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="demandFactor">
                                            <Form.Label column sm="3">
                                            Demand Factor
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                          type="text" 
                                                          placeholder="00.00"
                                                          onChange={this.setdemandFactor}
                                                          value={this.state.demandFactor} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="miscTax">
                                            <Form.Label column sm="3">
                                            Miscelleanous Charges 1
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control
                                                      type="text" 
                                                      placeholder="00.00"
                                                      onChange={this.setMisc1}
                                                      value={this.state.misc1} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="miscRemarkTax">
                                            <Form.Label column sm="3">
                                            Miscelleanous Remark
                                            </Form.Label>
                                            <Col sm="9">
                                            <Form.Control 
                                                         type="text"
                                                         placeholder="00.00" 
                                                         onChange={this.setmiscRemark}
                                                         value={this.state.miscRemark}/>
                                            </Col>
                                        </Form.Group>

                                        <Button  size="sm"  type="submit" variant='outline-info' >
                                            Submit
                                        </Button>
                                            <br/>
                                            <br/>
                                            <Alert show={this.state.showAlert} variant='info' onClose={this.setShow} dismissible>
                                                 {this.state.alertMessage}
                                                </Alert>
             
                                </Form>
                                    </Tab.Pane>
                                  
                       </Tab.Content>
                       
                    <br/>
                    <br/>
                    </Col></Row>
                    </Tab.Container>
                    
                </Container>
            </div>
        );
    }
}