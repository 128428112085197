import Axios from "axios";

export default function AddNewBill(billMonth,billNumber,accountNumber,meterStatus,issueDate,dueDate,billType,newReading,newReadingDate,oldReading,oldReadingDate,
    billPeriod,totalFixedCharges,unitsConsumed,totalConsumption,sopTypeConsumerValue,totalMuncipalTax,totalEd,totalInfrCess,
    miscCharges,miscRemarks,previousAdjustmentAmount,previousAdjustmentBillArrears,totalAmountByDate,totalAmountAfterDate,
    totalServiceCharges,totalMeterRent,totalTaxCharges,totalRentalCharges,finalPaymentAfter,
    preAdjustmentBillArrearsMonth,masterLoadPerMonthRate,loadCharges){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'addNewBill',
            billMonth,
            billNumber,
            accountNumber,
            meterStatus,
            issueDate,
            dueDate,
            billType,
            newReading,
            newReadingDate,
            oldReading,
            oldReadingDate,
            billPeriod,
            totalFixedCharges,
            unitsConsumed,
            totalConsumption,
            sopTypeConsumerValue,
            totalMuncipalTax,
            totalEd,
            totalInfrCess,
            miscCharges,
            miscRemarks,
            previousAdjustmentAmount,
            previousAdjustmentBillArrears,
            totalAmountByDate,
            totalAmountAfterDate,
            totalServiceCharges,
            totalMeterRent,
            totalTaxCharges,
            totalRentalCharges,
            finalPaymentAfter,
            preAdjustmentBillArrearsMonth,
            masterLoadPerMonthRate,
            loadCharges         
        }
    });
    return response;
}