import axios from "axios";

export  default function LoadCharges(){   
  let response=axios.get(process.env.REACT_APP_GATEWAY_URL,
     {
        params:{
        a: 'loadCharges',                
     }
   }
  );
  return response;
};
