import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import Input from '@material-ui/core/Input';
import {
    TimePicker,
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";

import Select from '@material-ui/core/Select';
import ViewParticularBillDetail from "../methods/ViewParticularBillDetail";
import SubmitPayment from "../methods/SubmitPayment";
import ViewSubmitStatus from "../methods/ViewSubmitStatus";
import ViewAlreadyPaidBillDetail from "../methods/ViewAlreadyPaidBillDetail";
import { RestorePageSharp } from '@material-ui/icons';

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class JEBillPaymentDialog extends React.Component{
    constructor(props){
        super(props);
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
        
       this.state={
            paymentDate:minDate,
            minDate:minDate,
            setOpenHelp:false,
            customerBillDetail:[],
            customerType:'',
            oldAccountNo:'',
            accountNo:'',
            name:'',
            emailId:'',
            loadKw:'',
            meterNo:'',
            meterRent:'',
            ctCharges:'',
            govPrivate:'',
            serviceCharges:'',
            billMonth:'',
            billNo:'',
            meterStatus:'',
            issueDate:'',
            dueDate:'',
            billType:'',
            newMeterReading:'',
            oldMeterReading:'',
            newMeterReadingDate:'',
            oldMeterReadingDate:'',
            billPeriod:'',
            totalFixedCharges:'',
            unitsConsumed:'',
            totalUnitsConsumed:'',
            totalEnergyCharges:'',
            muncipalCharges:'',
            edCharges:'',
            infrCellCharges:'',
            miscCharges:'',
            miscRemarks:'',
            amountPayable:'',
            amountPayableSurchargeAfter:'',
            amountPayableAfter:'',
            payableSubmitAmount:'',
            advanceAmount:'',
            paymentDate:minDate,
            pendingAmount:'',
            paymentMode:'',
            submitStatus:0,
            paymentTypeNo:'',
            message:''
           // billRecordIdPass:this.props.billRecordId       

        }
    }
    componentDidMount(){
      var billRecordId=this.props.billRecordId;
      ViewAlreadyPaidBillDetail(billRecordId).then(resp=>{
        if(resp){
          if(resp.data=='NO'){
            this.setState({
              message:'',
              paymentMode:'',
              paymentTypeNo:'',
              payableSubmitAmount:'',
              pendingAmount:'',
              submitStatus:0,
              readOnlyFlag:0
            })
          }
          else{
            this.setState({
              message:'Amount Has Been Already Paid for This Bill with Receipt Number :'+resp.data.billreceipt_id,
            paymentMode:resp.data.mode,
            paymentTypeNo:resp.data.chequeNo,
            paymentDate:resp.data.paymentDate,
            payableSubmitAmount:resp.data.paidAmount,
            pendingAmount:resp.data.pendingAmount,
            submitStatus:1 ,
            readOnlyFlag:1             
            })

          }
        }

      })
      ViewParticularBillDetail(billRecordId).then(resp=>{
        if(resp){
          if(resp.data==='No'){
            this.setState({
              customerBillDetail:[],
              message:'No record to display'
            })

          }
          else{
               this.setState({
            customerBillDetail:resp.data,
            customerType:resp.data[0].type,
            oldAccountNo:resp.data[0].oldAccountNo,
            accountNo:resp.data[0].accountNo,
            name:resp.data[0].name,
            emailId:resp.data[0].emailId,
            loadKw:resp.data[0].loadKw,
            meterNo:resp.data[0].meterNo,
            meterRent:resp.data[0].meterRentMonthly,
            ctCharges:resp.data[0].ctCharges,
            govPrivate:resp.data[0].govPrivate,
            serviceCharges:resp.data[0].serviceChargesMonthly,
            billMonth:resp.data[0].billMonth,
            billNo:resp.data[0].billNo,
            meterStatus:resp.data[0].meterSymbol,
            issueDate:resp.data[0].issueDate,
            dueDate:resp.data[0].dueDate,
            billType:resp.data[0].billType,
            newMeterReading:resp.data[0].NewMeterReading,
            oldMeterReading:resp.data[0].OldMeterReading,
            newMeterReadingDate:resp.data[0].NewMeterReadingDate,
            oldMeterReadingDate:resp.data[0].OldMeterReadingDate,
            billPeriod:resp.data[0].billPeriod,
            totalFixedCharges:resp.data[0].totalFixedCharges,
            unitsConsumed:resp.data[0].unitsConsumed,
            totalUnitsConsumed:resp.data[0].totalUnitsConsumed,
            totalEnergyCharges:resp.data[0].totalEnergyCharges,
            muncipalCharges:resp.data[0].muncipalCharges,
            edCharges:resp.data[0].edCharges,
            infrCellCharges:resp.data[0].infrCellCharges,
            miscCharges:resp.data[0].miscCharges,
            miscRemarks:resp.data[0].miscRemarks,
            amountPayable:resp.data[0].amountPayable,
            amountPayableSurchargeAfter:resp.data[0].amountPayableSurchargeAfter,
            amountPayableAfter:resp.data[0].amountPayableAfter,
               })
          }

        }
        else{

        }
      })
     // console.log(billRecordId)
    //  ViewSubmitStatus(billRecordId).then(resp=>{
    //       if(resp){
    //         if(resp.data==='No'){
    //           this.setState({
    //             submitStatus:0
    //           })
    //         }
    //         else{
    //           this.setState({
    //             message:'Already Entered',
    //             submitStatus:1,
    //             paymentMode:resp.data[0].mode,
    //             advanceAmount:resp.data[0].advanceAmount,
    //             pendingAmount:resp.data[0].pendingAmount,
    //             payableSubmitAmount:resp.data[0].paidAmount,
    //             paymentDate:resp.data[0].paymentDate
    //           })
    //         }

    //       }
    //       else{

    //       }
    //  })



    }

    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true,
            message:'',
        })
    }
    handleCancel = () => {
      this.setState({
        setOpenHelp:false           
      })         
    //setOpen(false);
  }

    setpaymentDate=(e)=>{
      this.setState({
        paymentDate:e
      })
  }

  setPaymentMode=(e)=>{
    this.setState({
      paymentMode:e.target.value
    })
  }

  // setAdvanceAmount=(e)=>{
  //   this.setState({
  //     advanceAmount:e.target.value  
  //   })
  // }

  setPaymentTypeNo=(e)=>{
    this.setState({  
        paymentTypeNo:e.target.value
    })
  }

  setPendingAmount=(e)=>{
    const {amountPayable,amountPayableAfter,dueDate}=this.state  
    var todayDateVar=new Date().getDate()+'-'+(new Date().getMonth()+1)+'-'+new Date().getFullYear()  
    console.log(todayDateVar>dueDate);  
    console.log(todayDateVar);
    console.log(dueDate);
    // if (todayDateVar-dueDate){
     //var amountPayableVar=amountPayableAfter
    //   console.log("if")
    // }
  

    // if(todayDateVar<dueDate){
    //   var amountPayableVar=amountPayable
    //   console.log("if2")
    // }
    
    // else{
    //   console.log("else")
    var amountPayableVar= amountPayable    
    // }
    console.log(amountPayableVar)
    var submittedAmountVar=Number(amountPayableVar)-Number(e.target.value)

    this.setState({
      pendingAmount:submittedAmountVar
    })
  }

  setPayableSubmitAmount=(e)=>{
    this.setState({
      payableSubmitAmount:e.target.value
    })
    this.setPendingAmount(e)
    }

  handleSubmitPayment=()=>{
    var billRecordId=this.props.billRecordId;
    const {payableSubmitAmount,paymentTypeNo,paymentDate,pendingAmount,paymentMode}=this.state
    // console.log(payableSubmitAmount,paymentTypeNo,paymentDate,pendingAmount,paymentMode)
    SubmitPayment(billRecordId,payableSubmitAmount,paymentTypeNo,paymentDate,pendingAmount,paymentMode).then(resp=>{
      if(resp){
          if(resp.data==="No"){
            this.setState({
              message:'Try Again'
            })
          }
          else{
            this.setState({
              message:'Payment Status Updated',
              submitStatus:1
            })
          }
      }
      else{

      }
    })


  }
    render(){
       const {message}=this.state
       //console.log(this.state.customerType);
        return(
            <div>
                <AddCommentOutlinedIcon fontSize='large' color='primary' onClick={this.setdialogHelp}/>
                <Dialog 
                    open={this.state.setOpenHelp}
                    onClose={this.handleNoHelp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" 
                    fullWidth={true}
                    maxWidth='lg'                        
                >
                    <DialogTitle id="alert-dialog-title">{"Payment Detail"}</DialogTitle>
                    <DialogContent>
                         
                         <Row>
                             <Col></Col>
                         </Row>
                         <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                         <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                             <Table style={{width:'100%'}} aria-label="customized table">
                             <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'> <b>1. CONSUMER DETAIL</b></StyledTableCell>                                                                      
                                   </TableRow>
                             </TableHead>
                             <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>AccountNumber</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>Old Account Number</b> </StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Name</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>EmailId</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Type</b></StyledTableCell>                                                                      
                                    </StyledTableRow>  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre">{this.state.accountNo}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.oldAccountNo}</StyledTableCell>                                 
                                  <StyledTableCell align="centre">{this.state.name}</StyledTableCell>
                                  <StyledTableCell align="centre"> {this.state.emailId} </StyledTableCell>
                                  <StyledTableCell align="centre"> {this.state.customerType.slice(3)} </StyledTableCell>                                                                  
                                  </StyledTableRow>

                                  <TableHead>                                    
                                   <TableRow>  
                                     <StyledTableCell align="centre" colSpan='5'><b>2.CONSUMER CHARGES</b></StyledTableCell>                                                                      
                                   </TableRow>
                                   </TableHead>                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"><b>Load (Kw)</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>Meter Rent</b> </StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>CT Charges</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Service Charges</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Type</b></StyledTableCell>                                                                      
                                    </StyledTableRow>  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre">{this.state.loadKw}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.meterRent}</StyledTableCell>                                 
                                  <StyledTableCell align="centre">{this.state.ctCharges}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.serviceCharges} </StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.customerType.slice(3)} </StyledTableCell>                                                                  
                                  </StyledTableRow>
         
                                  <TableHead>                                    
                                   <TableRow>  
                                     <StyledTableCell align="centre" colSpan='5'><b>3. READING</b></StyledTableCell>                                                                      
                                   </TableRow>
                                   </TableHead>                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"><b>New Meter Reading Date</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>Old Meter Reading Date</b> </StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Issue Date</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Due Date</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Bill Period</b></StyledTableCell>  
                                    </StyledTableRow>  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre">{this.state.newMeterReadingDate}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.oldMeterReadingDate}</StyledTableCell>                                 
                                  <StyledTableCell align="centre">{this.state.issueDate}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.dueDate} </StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.billPeriod} </StyledTableCell>                                                                                                  
                                  </StyledTableRow>

                                  <TableHead>                                    
                                   <TableRow>  
                                     <StyledTableCell align="centre" colSpan='5'><b>4. TAXES</b></StyledTableCell>                                                                      
                                   </TableRow>
                                   </TableHead>                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"><b>Fixed Charges</b></StyledTableCell>
                                  <StyledTableCell align="centre"><b>ED</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>IDF</b> </StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>M.C.Tax</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Misc Charges</b></StyledTableCell>                                     
                                    </StyledTableRow>  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre">{this.state.totalFixedCharges}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.edCharges}</StyledTableCell>                                 
                                  <StyledTableCell align="centre">{this.state.infrCellCharges}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.muncipalCharges} </StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.miscCharges} </StyledTableCell>                                                                                                  
                                  </StyledTableRow>

                                  <TableHead>                                    
                                   <TableRow>  
                                     <StyledTableCell align="centre" colSpan='5'><b>5. AMOUNT</b></StyledTableCell>                                                                      
                                   </TableRow>
                                   </TableHead>                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"><b>Adjustment Amount</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Unpaid Bill Arrears</b></StyledTableCell>        
                                  <StyledTableCell align="centre"><b>Amount Payable</b></StyledTableCell>
                                  <StyledTableCell align="centre"><b>Surcharges</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>Amount after Due Date</b></StyledTableCell>                                                    
                                                                 
                                    </StyledTableRow>  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"> 0.00 </StyledTableCell>
                                  <StyledTableCell align="centre"> 0.00 </StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.amountPayable}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.amountPayableSurchargeAfter}</StyledTableCell>                                 
                                  <StyledTableCell align="centre">{this.state.amountPayableAfter}</StyledTableCell>
                                                                                                                                    
                                  </StyledTableRow>

                                  <TableHead>                                    
                                   <TableRow>  
                                     <StyledTableCell align="centre" colSpan='5'><b>6. PAYMENT DETAIL</b></StyledTableCell>                                                                      
                                   </TableRow>
                                   </TableHead>                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"><b>Mode</b> <b style={{color:'red'}}>*</b> </StyledTableCell>
                                  <StyledTableCell align="centre"><b>UTR/CHEQUENO</b> <b style={{color:'red'}}>*</b> </StyledTableCell>
                                  <StyledTableCell align="centre"><b>Date</b> <b style={{color:'red'}}>*</b></StyledTableCell>  
                                  <StyledTableCell align="centre"><b>Amount Received</b> <b style={{color:'red'}}>*</b></StyledTableCell>
                                  <StyledTableCell align="centre"><b>Pending Amount</b> <b style={{color:'red'}}>*</b></StyledTableCell>                                                              
                                    </StyledTableRow>  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre" > 
                                    <Select style={{width:'70%'}}
                                           value={this.state.paymentMode} 
                                          onChange={this.setPaymentMode}
                                          readOnly={this.state.readOnlyFlag==1}>
                                           <option></option> 
                                           <option value="Cash">CASH</option>
                                           <option value="Cheque">CHEQUE</option>
                                           <option value="RTGS/NEFT">RTGS/NEFT</option>                                                                  
                                     </Select>                                   
                                  </StyledTableCell>   

                                  <StyledTableCell align="centre">
                                          <Input  id="advance"                                               
                                             type="number"  
                                             max={10}  
                                             min={6}
                                             value={this.state.paymentTypeNo}    
                                             onChange={this.setPaymentTypeNo}    
                                             placeholder="123 XXX "  
                                             required                          
                                             readOnly={this.state.readOnlyFlag==1}     
                                           />                                    
                                    </StyledTableCell>
                                      

                                  <StyledTableCell align="centre">
                                  <MuiPickersUtilsProvider  utils={DateFnsUtils}>      
                                        <DatePicker  
                                                minDate={this.state.minDate}
                                                maxDate={this.state.minDate}                                                 
                                                value={moment(this.state.paymentDate,'DD-MM-YYYY')}
                                                onChange={this.setpaymentDate}
                                                required
                                                readOnly={this.state.readOnlyFlag==1}
                                            />                    
                                  </MuiPickersUtilsProvider>
                                  
                                  </StyledTableCell>
                                  <StyledTableCell align="centre">
                                          <Input  id="payableAmount" 
                                              type="number"
                                              value={this.state.payableSubmitAmount}  
                                              onChange={this.setPayableSubmitAmount} 
                                              placeholder="Amount Received"    
                                              required      
                                              readOnly={this.state.readOnlyFlag==1?true:false}                                  
                                                  />
                                    </StyledTableCell>

                                  
                                  <StyledTableCell align="centre">
                                           <Input  id="pendingAmount" 
                                              type="number"
                                              value={this.state.pendingAmount}
                                              onChange={this.setPendingAmount}  
                                              placeholder="Pending Amount"
                                              readOnly    
                                              required      
                                              readOnly={this.state.readOnlyFlag==1}                                   
                                           />
                                  </StyledTableCell>                                                                                                                
                                  </StyledTableRow>                                  
                             </Table>
                             <br/>                           
                            
                             <DialogContentText id="alert-dialog-description">
                         {message && <Box p={3}><h5 style={{color: 'red'}}>{message}</h5></Box>} 
                         </DialogContentText>
                        </TableContainer>
                         </Paper>
                        
                    </DialogContent>
                    <DialogActions>
                    <Grid  container   direction="row" style={{marginLeft:'50%'}}>  
                      <Grid item style={{marginLeft:'20%',paddingRight:'1%',paddingLeft:'0%'}}>  
                        <Fab variant="extended" color="secondary" size="large"  
                            style={{ alignItems:'centre', marginLeft:'10%',marginRight:'3%'}} spacing={0}
                             onClick={this.handleCancel}                               
                                    >
                                   Cancel
                        </Fab>                                                     
                      </Grid>  
                      <Grid item style={{paddingRight:'0%',marginLeft:'20%',width:'0%'}}>                      
                        <Fab variant="extended" color="primary" size="large"  
                           style={{ alignItems:'centre',marginLeft:'10%',marginRight:'1%'}} 
                           disabled={this.state.submitStatus===1}                          
                           onClick={this.handleSubmitPayment}
                          >
                             {' '}
                          Submit
                          </Fab>  
  </Grid>    
   </Grid>                   
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}