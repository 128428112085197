import Axios from "axios";

export default function AddNewConsumer(accountNo,name,emailId,address,load,ctCharge,meterType,meterRent,type,consumerType,OldAccountNo,meterNo,serviceCharge){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'addNewConsumer',
            accountNo,
            name,
            emailId,
            address,
            load,
            ctCharge,
            meterType,
            meterRent,
            type,
            consumerType,
            OldAccountNo,
            meterNo,
            serviceCharge,
            
        }
    });
    return response;
}