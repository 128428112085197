import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { IconButton} from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import Input from '@material-ui/core/Input';
import {
    TimePicker,
    MuiPickersUtilsProvider, 
    DatePicker
  } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";

import Select from '@material-ui/core/Select';
import ViewParticularBillDetail from "../methods/ViewParticularBillDetail";
import SubmitPayment from "../methods/SubmitPayment";
import ViewSubmitStatus from "../methods/ViewSubmitStatus";

const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
export default class JEBillPaymentDialog extends React.Component{
    constructor(props){
        super(props);
        const minDate = new Date();
        const maxDate = new Date();
        minDate.setFullYear(minDate.getFullYear()); //.getFullYear()-1
        minDate.setHours(0, 0, 0, 0);
        maxDate.setFullYear(maxDate.getFullYear());//.getFullYear()+1
        maxDate.setHours(0, 0, 0, 0);
        
       this.state={
            paymentDate:minDate,
            minDate:minDate,
            setOpenHelp:false,
            customerBillDetail:[],
            customerType:'',
            oldAccountNo:'',
            accountNo:'',
            name:'',
            emailId:'',
            loadKw:'',
            meterNo:'',
            meterRent:'',
            ctCharges:'',
            govPrivate:'',
            serviceCharges:'',
            billMonth:'',
            billNo:'',
            meterStatus:'',
            issueDate:'',
            dueDate:'',
            billType:'',
            newMeterReading:'',
            oldMeterReading:'',
            newMeterReadingDate:'',
            oldMeterReadingDate:'',
            billPeriod:'',
            totalFixedCharges:'',
            unitsConsumed:'',
            totalUnitsConsumed:'',
            totalEnergyCharges:'',
            muncipalCharges:'',
            edCharges:'',
            infrCellCharges:'',
            miscCharges:'',
            miscRemarks:'',
            amountPayable:'',
            amountPayableSurchargeAfter:'',
            amountPayableAfter:'',
            payableSubmitAmount:'',
            advanceAmount:'',
            paymentDate:minDate,
            pendingAmount:'',
            paymentMode:'',
            submitStatus:0,
            paymentHistory:[],
           // billRecordIdPass:this.props.billRecordId       

        }
    }
    componentDidMount(){
      var billRecordId=this.props.billRecordId;
      ViewParticularBillDetail(billRecordId).then(resp=>{
        if(resp){
          if(resp.data==='No'){
            this.setState({
              customerBillDetail:[],
              message:'No record to display'
            })

          }
          else{
               this.setState({
            customerBillDetail:resp.data,
            customerType:resp.data[0].type,
            oldAccountNo:resp.data[0].oldAccountNo,
            accountNo:resp.data[0].accountNo,
            name:resp.data[0].name,
            emailId:resp.data[0].emailId,
            loadKw:resp.data[0].loadKw,
            meterNo:resp.data[0].meterNo,
            meterRent:resp.data[0].meterRent,
            ctCharges:resp.data[0].ctCharges,
            govPrivate:resp.data[0].govPrivate,
            serviceCharges:resp.data[0].serviceCharges,
            billMonth:resp.data[0].billMonth,
            billNo:resp.data[0].billNo,
            meterStatus:resp.data[0].meterSymbol,
            issueDate:resp.data[0].issueDate,
            dueDate:resp.data[0].dueDate,
            billType:resp.data[0].billType,
            newMeterReading:resp.data[0].NewMeterReading,
            oldMeterReading:resp.data[0].OldMeterReading,
            newMeterReadingDate:resp.data[0].NewMeterReadingDate,
            oldMeterReadingDate:resp.data[0].OldMeterReadingDate,
            billPeriod:resp.data[0].billPeriod,
            totalFixedCharges:resp.data[0].totalFixedCharges,
            unitsConsumed:resp.data[0].unitsConsumed,
            totalUnitsConsumed:resp.data[0].totalUnitsConsumed,
            totalEnergyCharges:resp.data[0].totalEnergyCharges,
            muncipalCharges:resp.data[0].muncipalCharges,
            edCharges:resp.data[0].edCharges,
            infrCellCharges:resp.data[0].infrCellCharges,
            miscCharges:resp.data[0].miscCharges,
            miscRemarks:resp.data[0].miscRemarks,
            amountPayable:resp.data[0].amountPayable,
            amountPayableSurchargeAfter:resp.data[0].amountPayableSurchargeAfter,
            amountPayableAfter:resp.data[0].amountPayableAfter,
               })
          }

        }
        else{

        }
      })
     // console.log(billRecordId)
     ViewSubmitStatus(billRecordId).then(resp=>{
          if(resp){
            if(resp.data==='No'){
              this.setState({
                submitStatus:0
              })
            }
            else{
              this.setState({
                message:'Already Entered',
                submitStatus:1,
                paymentHistory:resp.data,
                paymentMode:resp.data[0].mode,
                advanceAmount:resp.data[0].advanceAmount,
                pendingAmount:resp.data[0].pendingAmount,
                payableSubmitAmount:resp.data[0].paidAmount,
                paymentDate:resp.data[0].paymentDate
              })
            }

          }
          else{

          }
     })



    }

    setdialogHelp=()=>{
        this.setState({
            setOpenHelp:true,
            message:'',
        })
    }
    handleCancel = () => {
      this.setState({
        setOpenHelp:false           
      })         
    //setOpen(false);
  }

    setpaymentDate=(e)=>{
      this.setState({
        paymentDate:e
      })
  }

  setPaymentMode=(e)=>{
    this.setState({
      paymentMode:e.target.value
    })
  }

  setAdvanceAmount=(e)=>{
    this.setState({
      advanceAmount:e.target.value
    })
  }

  setPendingAmount=(e)=>{
    this.setState({
      pendingAmount:e.target.value
    })
  }
  setPayableSubmitAmount=(e)=>{
    this.setState({
      payableSubmitAmount:e.target.value
    })
  }

  handleSubmitPayment=()=>{
    var billRecordId=this.props.billRecordId;
    const {payableSubmitAmount,advanceAmount,paymentDate,pendingAmount,paymentMode}=this.state
    SubmitPayment(billRecordId,paymentDate,advanceAmount,pendingAmount,payableSubmitAmount,paymentMode).then(resp=>{
      if(resp){
          if(resp.data==="No"){
            this.setState({
              message:'Try Again'
            })
          }
          else{
            this.setState({
              message:'Payment Status Updated',
              submitStatus:1
            })
          }
      }
      else{

      }
    })


  }
    render(){
       const {message}=this.state
       //console.log(this.state.customerType);
        return(
            <div>
                <AddCommentOutlinedIcon fontSize='large' color='primary' onClick={this.setdialogHelp}/>
                <Dialog 
                    open={this.state.setOpenHelp}
                    onClose={this.handleNoHelp}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" 
                    fullWidth={true}
                    maxWidth='lg'                        
                >
                    <DialogTitle id="alert-dialog-title">{"Payment Detail"}</DialogTitle>
                    <DialogContent>
                         <DialogContentText id="alert-dialog-description">
                         {message && <Box p={3}><h5 style={{color: 'red'}}>{message}</h5></Box>} 
                         </DialogContentText>
                         <Row>
                             <Col></Col>
                         </Row>
                         <Paper rounded variant="outlined" style={{ flexGrow: 1}}>
                         <TableContainer component={Paper} style={{paddingTop:'0%'}}>
                             <Table style={{width:'100%'}} aria-label="customized table">
                             <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>History</StyledTableCell>                                                                      
                                   </TableRow>
                             </TableHead>
                             <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>Mode</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>chequeNo</b> </StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>paidAmount</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Date</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Pending</b></StyledTableCell>                                                                      
                             </StyledTableRow>

                          {this.state.paymentHistory.map((history,id)=>(

                                <StyledTableRow>
                                <StyledTableCell align="centre">{history.mode}</StyledTableCell>
                                <StyledTableCell align="centre">{history.chequeNo}</StyledTableCell>                                 
                                <StyledTableCell align="centre">{history.paidAmount}</StyledTableCell>
                                <StyledTableCell align="centre">{history.paymentDate} </StyledTableCell>
                                <StyledTableCell align="centre">{history.pendingAmount} </StyledTableCell>                                                                  
                                </StyledTableRow>
                               
                              //  <StyledTableRow>
                              //      <StyledTableCell align="centre"><b>{history.mode}</b></StyledTableCell> 
                              //      <StyledTableCell align="centre"><b>{history.chequeNo}</b> </StyledTableCell>                                                    
                              //       <StyledTableCell align="centre"><b>{history.paidAmount}</b> </StyledTableCell>
                              //       <StyledTableCell align="centre"><b>{history.paymentDate}</b></StyledTableCell>
                              //       <StyledTableCell align="centre"><b>{history.pendingAmount}</b></StyledTableCell>                                                                      
                              //       </StyledTableRow>
                          ))}


                             
                             <TableHead>                                    
                                   <TableRow >  
                                     <StyledTableCell align="centre" colSpan='5'>Consumer Details</StyledTableCell>                                                                      
                                   </TableRow>
                             </TableHead>
                             <StyledTableRow> 
                                   <StyledTableCell align="centre"><b>AccountNumber</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>Old Account Number</b> </StyledTableCell>                                                    
                                    <StyledTableCell align="centre"><b>Name</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>EmailId</b></StyledTableCell>
                                    <StyledTableCell align="centre"><b>Type</b></StyledTableCell>                                                                      
                                    </StyledTableRow>  
                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre">{this.state.accountNo}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.oldAccountNo}</StyledTableCell>                                 
                                  <StyledTableCell align="centre">{this.state.name}</StyledTableCell>
                                  <StyledTableCell align="centre"> {this.state.emailId} </StyledTableCell>
                                  <StyledTableCell align="centre"> {this.state.customerType.slice(3)} </StyledTableCell>                                                                  
                                  </StyledTableRow>

                                 
                                  <TableHead>                                    
                                   <TableRow>  
                                     <StyledTableCell align="centre" colSpan='5'> Amount</StyledTableCell>                                                                      
                                   </TableRow>
                                   </TableHead>                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"><b>Adjustment Amount</b> </StyledTableCell>
                                    <StyledTableCell align="centre"><b>Unpaid Bill Arrears</b></StyledTableCell>        
                                  <StyledTableCell align="centre"><b>Amount Payable</b></StyledTableCell>
                                  <StyledTableCell align="centre"><b>Surcharges</b></StyledTableCell> 
                                   <StyledTableCell align="centre"><b>Amount after Due Date</b></StyledTableCell>                                                    
                                                                 
                                    </StyledTableRow>  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"> 0.00 </StyledTableCell>
                                  <StyledTableCell align="centre"> 0.00 </StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.amountPayable}</StyledTableCell>
                                  <StyledTableCell align="centre">{this.state.amountPayableSurchargeAfter}</StyledTableCell>                                 
                                  <StyledTableCell align="centre">{this.state.amountPayableAfter}</StyledTableCell>
                                                                                                                                    
                                  </StyledTableRow>

                                  <TableHead>                                    
                                   <TableRow>  
                                     <StyledTableCell align="centre" colSpan='5'>Payment Detail</StyledTableCell>                                                                      
                                   </TableRow>
                                   </TableHead>                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"><b>Mode</b> <b style={{color:'red'}}>*</b> </StyledTableCell>
                                  <StyledTableCell align="centre"><b>UTR/CHEQUENO</b> <b style={{color:'red'}}>*</b> </StyledTableCell>
                                  <StyledTableCell align="centre"><b>Date</b> <b style={{color:'red'}}>*</b></StyledTableCell>  
                                  <StyledTableCell align="centre"><b>Payable Amount</b> <b style={{color:'red'}}>*</b></StyledTableCell>
                                  <StyledTableCell align="centre"><b>Pending Amount</b> <b style={{color:'red'}}>*</b></StyledTableCell>       
                                 
                                  
                                                                                      
                                                                 
                                    </StyledTableRow>  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre" > 
                                    <Select style={{width:'70%'}} value={this.state.paymentMode} onChange={this.setPaymentMode}>
                                           <option></option> 
                                           <option value="Cash">CASH</option>
                                           <option value="Cheque">CHEQUE</option>
                                           <option value="RTGS/NEFT">RTGS/NEFT</option>                                                                  
                                     </Select>                                   
                                  </StyledTableCell>   

                                  <StyledTableCell align="centre">
                                          <Input  id="advance"                                               
                                             type="number"  
                                             max={10}  
                                             min={6}
                                             value={this.state.advanceAmount}    
                                             onChange={this.setAdvanceAmount}    
                                             placeholder="123 XXX "  
                                             required                               
                                           />                                    
                                    </StyledTableCell>  

                                  <StyledTableCell align="centre">
                                  <MuiPickersUtilsProvider  utils={DateFnsUtils}>      
                                        <DatePicker  
                                                minDate={this.state.minDate}
                                                maxDate={this.state.minDate}                                                 
                                                value={moment(this.state.paymentDate,'DD-MM-YYYY')}
                                                onChange={this.setpaymentDate}
                                                required
                                            />                    
                                  </MuiPickersUtilsProvider>
                                  
                                  </StyledTableCell>
                                  <StyledTableCell align="centre">
                                          <Input  id="payableAmount" 
                                              type="number"
                                              value={this.state.payableSubmitAmount}  
                                              onChange={this.setPayableSubmitAmount} 
                                              placeholder="Payable Amount"    
                                              required                                        
                                                  />
                                    </StyledTableCell>

                                  
                                  <StyledTableCell align="centre">
                                           <Input  id="pendingAmount" 
                                              type="number"
                                              value={this.state.pendingAmount}
                                              onChange={this.setPendingAmount}  
                                              placeholder="Pending Amount"    
                                              required                                         
                                           />
                                  </StyledTableCell>                                                                                                                                                                        
                                  </StyledTableRow>


                                  <TableHead>                                    
                                   <TableRow>  
                                     <StyledTableCell align="centre" colSpan='5'>Payment History</StyledTableCell>                                                                      
                                   </TableRow>
                                   </TableHead>                                  
                                  <StyledTableRow>
                                  <StyledTableCell align="centre"><b>Mode</b> <b style={{color:'red'}}>*</b> </StyledTableCell>
                                  <StyledTableCell align="centre"><b>UTR/CHEQUENO</b> <b style={{color:'red'}}>*</b> </StyledTableCell>
                                  <StyledTableCell align="centre"><b>Date</b> <b style={{color:'red'}}>*</b></StyledTableCell>  
                                  <StyledTableCell align="centre"><b>Payable Amount</b> <b style={{color:'red'}}>*</b></StyledTableCell>
                                  {/* <StyledTableCell align="centre"><b>Pending Amount</b> <b style={{color:'red'}}>*</b></StyledTableCell>        */}
                                                        
                                  </StyledTableRow>

                               
                                 
                                  

                             </Table>
                        </TableContainer>
                         </Paper>
                    </DialogContent>
                    <DialogActions>
                    <Grid  container   direction="row" style={{marginLeft:'50%'}}>  
                      <Grid item style={{marginLeft:'20%',paddingRight:'1%',paddingLeft:'0%'}}>  
                        <Fab variant="extended" color="secondary" size="large"  
                            style={{ alignItems:'centre', marginLeft:'10%',marginRight:'3%'}} spacing={0}
                             onClick={this.handleCancel} 
                              
                                    >
                                   Cancel
                        </Fab>
                                                     
                      </Grid>
  
                      <Grid item style={{paddingRight:'0%',marginLeft:'20%',width:'0%'}}>                      
                        <Fab variant="extended" color="primary" size="large"  
                           style={{ alignItems:'centre',marginLeft:'10%',marginRight:'1%'}} 
                           disabled={this.state.submitStatus===1}                          
                           onClick={this.handleSubmitPayment}
                          >
                             {' '}
                          Submit
                          </Fab>
  
  </Grid> 
   
   </Grid>
                    
                   
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}