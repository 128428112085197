import Axios from "axios";

export default function GenerateBillNo(recordId,billMonth){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'generateBillNo',
            recordId,
            billMonth            
        }
    });
    return response;
}