import Axios from "axios";

export default function EditConsumer(recordId,accountNo,name,emailId,address,load,ctCharge,meterType,meterRent,type,consumerType){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'editConsumer',
            recordId,
            accountNo,
            name,
            emailId,
            address,
            load,
            ctCharge,
            meterType,
            meterRent,
            type,
            consumerType
        }
    });
    return response;
}