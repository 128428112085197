import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';  
import Alert from 'react-bootstrap/Alert';
import Button from "react-bootstrap/Button";
//import MaterialTable from 'material-table';
import ViewAllBillRecordAdmin from '../methods/ViewAllBillRecordAdmin';
import ApproveBillAdmin from '../methods/ApproveBillAdmin';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import MaterialTable from 'material-table';

import ViewApprovalPendingRecordAdmin from '../methods/ViewApprovalPendingRecordAdmin';
import ViewAllBillExtendDate from '../methods/ViewAllBillExtendDate';



export default class ApproveBill extends React.Component {
     constructor(props){
         super(props);    
        this.state={        
        message:'',       
        vColor:'',
        show:false,
        setShow:false,
        showAlert:false,
        allBillRecord:[],
        allApprovalPending:[],
        allBillExtendDate:[],
        
    }
}
    componentDidMount=()=>{
           
            // this.handleApproveBillAdmin();
            this.handleViewAllBillRecordAdmin();
            this.handleViewApprovalPendingRecordAdmin();
            // this.handleViewAllBillExtendDate();
    }

    handleViewAllBillExtendDate=()=>{
        ViewAllBillExtendDate().then(resp=>{
            if(resp){
                if(resp.data==='No'){
                    this.setState({
                        message:'No Record Found',
                        vColor:'danger',
                        allBillExtendDate:[]
                    })
                }
                else{
                    this.setState({
                        // message:'',
                        // vColor:'',
                        allBillExtendDate:resp.data
                    })
                }
            }
            else{    }
        })
    }

   

    handleViewAllBillRecordAdmin=()=>{
        ViewAllBillRecordAdmin().then(resp=>{
            if(resp){
                if(resp.data==='No'){
                    this.setState({
                        message:'No Record Found',
                        vColor:'danger',
                        allBillRecord:[]
                    })
                }
                else{
                    this.setState({
                        // message:'',
                        // vColor:'',
                       allBillRecord:resp.data
                    })
                }
            }
            else{    }
        })
    }

    handleViewApprovalPendingRecordAdmin=()=>{
        ViewApprovalPendingRecordAdmin().then(resp=>{
            if(resp){
                if(resp.data==='No'){
                    this.setState({
                        message:'No Record Found',
                        vColor:'danger',
                        allApprovalPending:[]
                    })
                }
                else{
                    this.setState({
                        // message:'',
                        // vColor:'',
                        allApprovalPending:resp.data
                    })
                   
                }
            }
            else{

            }
        })
    }





    
    handleApproveBillAdmin(billrecord_id){
      
         ApproveBillAdmin(billrecord_id).then(resp=>{
             if(resp){
                 console.log(resp.data)
 
                 this.setState({
                     message:resp.data,
                     showAlert:true,
                     vColor:'success'   
                 })              
                 this.handleViewAllBillRecordAdmin();
                 this.handleViewApprovalPendingRecordAdmin();
             }
         })
         
       
           
        
     }
 
     setShow=()=>{
         this.setState({
             showAlert:false
         })
     }
 
 



    render(){        
        //console.log(this.state.allApprovalPending.length)
        return(
            <div>
                <Container fluid>
                {/* <Row>
                    <Col style={{textAlign:'center'}}><h3><b><em><u>Consumer Bill Approval</u></em></b></h3></Col>
                </Row>
                <br/>
                <br/> */}

                <Alert show={this.state.showAlert} variant={this.state.vColor} onClose={this.setShow} dismissible>
                         {this.state.message}
                </Alert>


                <Tab.Container  defaultActiveKey="approved" transition={false} id="controlled-tab-example">
                    <Row>
                        <Col>
                                <Nav variant="tabs" defaultActiveKey="approved">
                                    <Nav.Item>
                                        <Nav.Link eventKey="approved">Approved Bill
                                        <b style={{color:'red',paddingLeft:'5px'}}>({this.state.allBillRecord.length-this.state.allApprovalPending.length})</b>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pending">Approval Pending 
                                           <b style={{color:'red',paddingLeft:'4px'}}>({this.state.allApprovalPending.length})</b>
                                            
                                         </Nav.Link>
                                    </Nav.Item>
                                    {/* <Nav.Item>
                                        <Nav.Link eventKey="extendDate">
                                             Extend Date of Approved Bill
                                            <b style={{color:'red',paddingLeft:'4px'}}>
                                              
                                            </b>
                                            
                                         </Nav.Link>
                                    </Nav.Item> */}
                                </Nav>
                        </Col>
                    </Row>                    

                    {/* {this.state.message? 
                    <Row>
                        <Col style={{paddingTop:'5px'}}>
                                 <Alert  show={this.state.showAlert}  variant={this.state.vColor} dismissible onClose={this.setShow}  >
                                            {this.state.message}
                                        </Alert>
                        </Col>
                    </Row>
                         :''} */}

                    <Row>
                        <Col style={{paddingTop:'10px'}}>
                             <Tab.Content sm={12}>
                                 <Tab.Pane eventKey="approved">
                                     <Form>
                                     <Row>
                                    <Col>
                        <MaterialTable
                                                        name="Bill  Payment Approval"
                                                            title="Bill  Payment Approval"   
                                    
                                                            columns  ={[
                                                            {title:'S.No',field:'sno'}, 
                                                            {title:'BillMonth ', field:'billMonth'},
                                                            {title:'Bill No ', field:'billNo'},
                                                            {title:'Acccount No.',field:'accNo'},
                                                            {title:'CustomerName',field:'name'},                                                          
                                                            {title:'IssueDate',field:'issueDate'},
                                                            {title:'DueDate',field:'dueDate'},
                                                            {title:'BillType',field:'billType'},
                                                            {title:'NewReading',field:'newReading'},
                                                            {title:'OldReading',field:'oldReading'},
                                                            {title:'AmountPayable',field:'amountPayable'},
                                                            {title:'AmountPayable After Date',field:'amountPayableAfterDate'},  
                                                            {title:'Action',field:'action'},                                                          
                                                          //  {title:'+More',field:'view'},
                                                                                                            
                                                        ]}
                                                data={
                                                this.state.allBillRecord.map((allBill,id)=>(
                                                    {
                                                        'sno':id+1,
                                                        'billMonth':allBill.billMonth,
                                                        'billNo':allBill.billNo,
                                                        'accNo':allBill.accountNo,
                                                        'name':allBill.name,
                                                        'issueDate':allBill.issueDate,
                                                        'dueDate':allBill.dueDate,
                                                        'billType':allBill.billType,
                                                        'newReading':allBill.NewMeterReading,
                                                        'oldReading':allBill.OldMeterReading,
                                                        'amountPayable':allBill.amountPayable,
                                                        'amountPayableAfterDate':allBill.amountPayableAfter,
                                                        'action':allBill.billStatus?'Bill Approved':(<Button size="sm"  variant='outline-success'   
                                                        onClick={this.handleApproveBillAdmin.bind(this,allBill.billrecord_id)}> Approve</Button>),
                                                                    
                                                        //'view':<JEBillPaymentDialog billRecordId={allBill.billrecord_id}/>                                 

                                                    }
                                                ))
                                            }
                                            options={{
                                                exportButton: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 20, 50, 100,this.state.allBillRecord.length],
                                                toolbar: true,
                                                paging: true
                                                
                                                }} 
                                                   />
                                            </Col>
                                        </Row>                                         <br></br>
                                         <Row>
                                             <Col></Col>
                                         </Row>
                                     </Form>

                                 </Tab.Pane>

                                 <Tab.Pane eventKey="pending">                                
                                 <Form>
                                     <Row>
                                    <Col>
                                                 <MaterialTable
                                                            name="Bill  Payment Approval"
                                                                title="Bill  Payment Approval"   
                                        
                                                                columns  ={[
                                                                {title:'S.No',field:'sno'}, 
                                                                {title:'BillMonth ', field:'billMonth'},
                                                                {title:'Bill No ', field:'billNo'},
                                                            {title:'Acccount No.',field:'accNo'},
                                                            {title:'CustomerName',field:'name'},                                                          
                                                            {title:'IssueDate',field:'issueDate'},
                                                            {title:'DueDate',field:'dueDate'},
                                                            {title:'BillType',field:'billType'},
                                                            {title:'NewReading',field:'newReading'},
                                                            {title:'OldReading',field:'oldReading'},
                                                            {title:'AmountPayable',field:'amountPayable'},
                                                            {title:'AmountPayable After Date',field:'amountPayableAfterDate'},  
                                                            {title:'Action',field:'action'},                                                          
                                                          //  {title:'+More',field:'view'},
                                                                                                            
                                                        ]}
                                                data={
                                                this.state.allApprovalPending.map((pendingApproval,id)=>(
                                                    {
                                                        'sno':id+1,
                                                        'billMonth':pendingApproval.billMonth,
                                                        'billNo':pendingApproval.billNo,
                                                        'accNo':pendingApproval.accountNo,
                                                        'name':pendingApproval.name,
                                                        'issueDate':pendingApproval.issueDate,
                                                        'dueDate':pendingApproval.dueDate,
                                                        'billType':pendingApproval.billType,
                                                        'newReading':pendingApproval.NewMeterReading,
                                                        'oldReading':pendingApproval.OldMeterReading,
                                                        'amountPayable':pendingApproval.amountPayable,
                                                        'amountPayableAfterDate':pendingApproval.amountPayableAfter,
                                                        'action':pendingApproval.billStatus?'Bill Approved':(<Button size="sm"  variant='outline-success'   
                                                        onClick={this.handleApproveBillAdmin.bind(this,pendingApproval.billrecord_id)}> Approve</Button>),
                                                                    
                                                        //'view':<JEBillPaymentDialog billRecordId={allBill.billrecord_id}/>                                 

                                                    }
                                                ))
                                            }
                                            options={{
                                                exportButton: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 20, 50, 100,this.state.allBillRecord.length],
                                                toolbar: true,
                                                paging: true
                                                
                                                }} 
                                                   />
                                            </Col>
                                        </Row>                                         <br></br>
                                         <Row>
                                             <Col></Col>
                                         </Row>
                                     </Form>
                                 </Tab.Pane>

                                 <Tab.Pane eventKey="extendDate">
                                     <Form>
                                     <Row>
                                    <Col>
                        <MaterialTable
                                                        name="Extend Date"
                                                            title="Extend Date"   
                                    
                                                            columns  ={[
                                                            {title:'S.No',field:'sno'}, 
                                                            {title:'BillMonth ', field:'billMonth'},
                                                            {title:'Bill No ', field:'billNo'},
                                                            {title:'Acccount No.',field:'accNo'},
                                                            {title:'CustomerName',field:'name'},                                                          
                                                            {title:'IssueDate',field:'issueDate'},
                                                            {title:'DueDate',field:'dueDate'},
                                                            {title:'BillType',field:'billType'},
                                                            {title:'NewReading',field:'newReading'},
                                                            {title:'OldReading',field:'oldReading'},
                                                            {title:'AmountPayable',field:'amountPayable'},
                                                            {title:'AmountPayable After Date',field:'amountPayableAfterDate'},  
                                                            {title:'Action',field:'action'},                                                          
                                                          //  {title:'+More',field:'view'},
                                                                                                            
                                                        ]}
                                                data={
                                                this.state.allBillExtendDate.map((allBillExtended,id)=>(
                                                    {
                                                        'sno':id+1,
                                                        'billMonth':allBillExtended.billMonth,
                                                        'billNo':allBillExtended.billNo,
                                                        'accNo':allBillExtended.accountNo,
                                                        'name':allBillExtended.name,
                                                        'issueDate':allBillExtended.issueDate,
                                                        'dueDate':allBillExtended.dueDate,
                                                        'billType':allBillExtended.billType,
                                                        'newReading':allBillExtended.NewMeterReading,
                                                        'oldReading':allBillExtended.OldMeterReading,
                                                        'amountPayable':allBillExtended.amountPayable,
                                                        'amountPayableAfterDate':allBillExtended.amountPayableAfter,
                                                        // 'action':allBill.billStatus?'Bill Approved':(<Button size="sm"  variant='outline-success'   
                                                        // onClick={this.handleApproveBillAdmin.bind(this,allBill.billrecord_id)}> Approve</Button>),
                                                                    
                                                        //'view':<JEBillPaymentDialog billRecordId={allBill.billrecord_id}/>                                 

                                                    }
                                                ))
                                            }
                                            options={{
                                                exportButton: true,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 20, 50, 100,this.state.allBillRecord.length],
                                                toolbar: true,
                                                paging: true
                                                
                                                }} 
                                                   />
                                            </Col>
                                        </Row>                                         <br></br>
                                         <Row>
                                             <Col></Col>
                                         </Row>
                                     </Form>

                                 </Tab.Pane>
                             </Tab.Content>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <br/>
                </Tab.Container>             
                                                <br/>
                                                <br/>
                                                <br/>
                                                
                              </Container>
            </div>
        )
    }
 }