import Axios from "axios";

export default function SaveLoad(loadYear,loadUpto7,loadUpto20,loadUpto100,loadAbove){
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'saveLoad',
            loadYear,
            loadUpto7,
            loadUpto20,
            loadUpto100,
            loadAbove
          
        }
    });
    return response;
}