import Axios from "axios";

export default function SubmitPayment(billRecordId,payableSubmitAmount,paymentTypeNo,paymentDate,pendingAmount,paymentMode){    
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL, {
        params: {
            a: 'submitPayment',
            billRecordId,
            payableSubmitAmount,
            paymentTypeNo   ,
            paymentDate,
            pendingAmount,
            paymentMode
        }
    });
    return response;
}