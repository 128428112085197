import Axios from "axios";

export default function downloadBill(
  billrecord_id,billMonth

){
 
    let response = Axios.get(process.env.REACT_APP_GATEWAY_URL,{
      
        responseType: 'arraybuffer',
      headers: {
        'Accept': 'application/pdf'
      }, 
        params: {
            a: 'downloadBill',
            billrecord_id,
            billMonth

           
        }
        
    });

    return response;
}